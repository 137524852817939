import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { getTurnAroundTimeMinMedianMax } from "../helpers/utils"
import { CheckedMinMedianMax } from "../portPredictorReducer"
import { PortPredictorCargoGroupTypes, VesselActivity, usePortPredictorAPI } from "./usePortPredictorAPI"
export interface FetchPortTurnAroundTimeParams {
  segment: string
  cargo: string | undefined
  locode: string | undefined
  activity: VesselActivity | undefined
  cargoGroup: PortPredictorCargoGroupTypes | undefined
  terminal_id: string | null | undefined
}

export interface TurnAroundTimeMinMedianMax {
  [CheckedMinMedianMax.Min]: { label: CheckedMinMedianMax.Min, value: number },
  [CheckedMinMedianMax.Median]: { label: CheckedMinMedianMax.Median, value: number },
  [CheckedMinMedianMax.Average]: { label: CheckedMinMedianMax.Average, value: number },
  [CheckedMinMedianMax.Max]: { label: CheckedMinMedianMax.Max, value: number },
}


export function useFetchPortTurnAroundTime({
  segment,
  cargo,
  locode,
  activity,
  cargoGroup,
  terminal_id
}: FetchPortTurnAroundTimeParams) {

  const { getPortTurnAroundTime } = usePortPredictorAPI()

  const {
    isLoading: isPortTurnAroundTimeLoading,
    isError: isPortTurnAroundTimeError,
    isSuccess: isPortTurnAroundTimeSuccess,
    data: portTurnAroundTimeData,
  } = useQuery({
    queryKey: [
      "portPredictor/getPortTurnAroundTime",
      {
        segment,
        cargo,
        locode,
        activity,
        cargoGroup,
        terminal_id
      },
    ],
    queryFn: ({
      queryKey,
    }: QueryFunctionContext<(string | FetchPortTurnAroundTimeParams)[]>) => {
      return getPortTurnAroundTime(queryKey[1] as FetchPortTurnAroundTimeParams)
    },
    enabled: !!(segment && locode && cargo && activity && cargoGroup), // Ensure all props are there
    staleTime: 600000, // 10 minutes
  })

  const portTurnAroundTimeMinMedianMax = getTurnAroundTimeMinMedianMax(portTurnAroundTimeData)
  return {
    portTurnAroundTimeMinMedianMax,
    portTurnAroundTimeData,
    isPortTurnAroundTimeLoading,
    isPortTurnAroundTimeError,
    isPortTurnAroundTimeSuccess,
  }
}

