import { Itineraries, Itinerary } from "modules/PortPredictor/hooks/useItineraryAPI"

// If the name is longer than 15 characters, return the first 13 characters followed by '...'
export const TrimName = (name: string): string =>
    name.length > 15 ? name.substring(0, 13) + "..." : name


export const FormatDateDisplay = (createdDate: string) => {
    return new Date(createdDate)
        .toISOString()
        .replace(/T/, " ") // replace T with a space
        .replace(/\..+/, "")
}

export const MergeItineraries = (
    original: Itineraries,
    newArray: Itineraries
): Itineraries => {
    const mergedMap: Map<number, Itinerary> = new Map()

    // Add items from the original array to the map
    original && original.forEach((item) => mergedMap.set(item.id!, item))

    // Update or add items from the new array to the map
    newArray &&
        newArray.forEach((item) =>
            mergedMap.set(item.id!, { ...mergedMap.get(item.id!), ...item })
        )

    // Convert the map values back to an array
    const mergedArray: Itineraries = Array.from(mergedMap.values())

    return mergedArray
}

export const GetHighestItinearyId = (
    itineraries: Itineraries,
    filterCallback?: ((item: Itinerary) => boolean | undefined) | null
): number => {
    return (itineraries ?? [])
        .filter(
            filterCallback !== null && filterCallback !== undefined
                ? filterCallback
                : () => true // return all if nothing is passed
        )
        .reduce((maxIndex, currentItinerary) => {
            const match = currentItinerary.name.match(/Tab (\d+)/)

            if (match) {
                const currentIndex = parseInt(match[1], 10)
                return currentIndex > maxIndex ? currentIndex : maxIndex
            }
            return maxIndex
        }, 0)
}

export const GetHighestGapItineraryId = (
    itineraries: Itineraries,
    filterCallback?: ((item: Itinerary) => boolean | undefined) | null
): number => {
    // Extract all Tab numbers from the itinerary names
    const tabNumbers = (itineraries ?? [])
        .filter(
            filterCallback !== null && filterCallback !== undefined
                ? filterCallback
                : () => true // return all if nothing is passed
        )
        .map((currentItinerary) => {
            const match = currentItinerary.name.match(/Tab (\d+)/);
            return match ? parseInt(match[1], 10) : null;
        })
        .filter((num): num is number => num !== null) // filter out non-Tab names
        .sort((a, b) => a - b); // sort the Tab numbers in ascending order

    if (tabNumbers.length === 0) {
        return 1; // Return 1 if no 'Tab' names are found
    }

    // Check for gaps in the Tab sequence
    for (let i = 0; i < tabNumbers.length; i++) {
        if (tabNumbers[i] !== i + 1) {
            return i + 1; // Return the first gap number
        }
    }

    // No gaps, return the max plus one
    return tabNumbers[tabNumbers.length - 1] + 1;
};


export const GetHighestItinerarySeqId = (itineraries: Itineraries): number => {
    if (!itineraries || itineraries.length === 0) {
        // Return a default value if the array is empty or undefined
        return 0
    }

    return Math.max(
        ...itineraries.map((itinerary) => itinerary.openSeqId || 0),
        0
    )
}

export const GetNextSelectedId = (
    itineraries: Itineraries,
    deletedId: number
): number => {
    if (deletedId === 0 || !itineraries) return 1

    const openedItineraries = itineraries.filter((item) => item.isOpened)
    const index = openedItineraries.findIndex(
        (itinerary) => itinerary.id === deletedId
    )
    if (index > 0) {
        // Return the id of the item just before the deleted item
        return openedItineraries[index - 1].id || deletedId
    } else if (index === 0 && openedItineraries.length > 1) {
        return openedItineraries[index + 1].id || deletedId
    }

    // If the deleted item is not found or it's the first item, return 1
    return deletedId
}

export const CapitalizeFirstLetter = (value: string) => {
    return value && value[0].toUpperCase() + value.slice(1)
}

export const DeepEqualIgnoreNullUndefined = (obj1: any, obj2: any): boolean => { 
    const result = DeepEqual(obj1, obj2)
    return !!!result.length
}

const DeepEqual = (obj1: any, obj2: any, path = ''): string[] => {
    const isObject = (obj: any) => obj !== null && typeof obj === 'object';
    let diffKeys: string[] = [];

    // If both are strictly equal, return an empty array (no differences)
    if (obj1 === obj2) return diffKeys;

    // If one is undefined or null and the other isn't, return the path (only if path is not empty)
    if ((obj1 === undefined || obj1 === null) && obj2 !== undefined && obj2 !== null) {
        return path ? [path] : [];
    }
    if ((obj2 === undefined || obj2 === null) && obj1 !== undefined && obj1 !== null) {
        return path ? [path] : [];
    }

    // If both are objects, compare their keys and values recursively
    if (isObject(obj1) && isObject(obj2)) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        const allKeys = new Set([...keys1, ...keys2]);

        // Iterate over all keys from both objects
        for (const key of allKeys) {
            const value1 = obj1[key];
            const value2 = obj2[key];
            const currentPath = path ? `${path}.${key}` : key; // Build dot notation path

            // Skip if the key in one object is null/undefined and doesn't exist in the other
            if ((value1 === undefined || value1 === null) && !(key in obj2)) continue;
            if ((value2 === undefined || value2 === null) && !(key in obj1)) continue;

            // Recurse into deeper objects
            const deeperDiff = DeepEqual(value1, value2, currentPath);
            diffKeys = diffKeys.concat(deeperDiff);
        }
    } else {
        // If values are not objects and not equal, add the path (only if path is not empty)
        if (path) diffKeys.push(path);
    }

    return diffKeys;
}
