import { VesselListSingle } from "../components/PortPredictorMain"

export function formatVesselNamesForDropdown(vesselsData: VesselListSingle[] | undefined) {
  if (!!vesselsData && Array.isArray(vesselsData)) {
    return vesselsData
      ?.sort((a, b) => {
        return a.vessel_name.localeCompare(b.vessel_name)
      })
      .map((vessel) => {
        return {
          label: `${vessel.vessel_name.toUpperCase()} (${vessel.imo})`,
          value: String(vessel.imo),
        }
      })
  }
}
