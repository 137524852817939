import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SelectOption, VesselSegmentInputSelection } from 'modules/VesselSearch/VesselSearchMain'
import { useState } from 'react'
import { PortPredictorJourneyMarker } from "../portPredictorReducer"
import ItineraryService from './itineraryService'
import { PortPredictorVesselStateDataWithMajorCargo } from './usePortPredictorAPI'
// import { SelectOption } from 'modules/VesselSearch/VesselSearchMain'

export interface Markers {
  data?: PortPredictorJourneyMarker[],
  meta?: {
    organization: string,
    segment: VesselSegmentInputSelection,
    vessel: SelectOption | undefined,
    data: Record<string, any>
  }
}

export interface ItineraryBase {
  id?: number
  uuid?: string
  name: string
  markers?: Markers
  clientVersion?: string
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  isDeleted?: boolean
}

export interface Itinerary extends ItineraryBase {
  // Added for UI
  isModified?: boolean
  isOpened?: boolean
  isNew?: boolean
  isEditMode?: boolean
  openSeqId?: number
}

export type Itineraries = Itinerary[] | undefined


export const useItineraries = (uuid: string) => {
  const queryClient = useQueryClient()
  const [currUuid, setCurrUuid] = useState(uuid)
  const itineraryService = ItineraryService()

  // Fetch itineraries
  const { data: itineraries, isLoading } = useQuery(['itineraries'], itineraryService.getItineraries)
  const { data: itineraryWithMarker, isLoading: isItineraryWithMarkerLoading, refetch: refetchItineraryWithMarker } = useQuery(
    ['itinerary', currUuid], // Include uuid in the query key
    ({ queryKey }) => {
      const [_, queryKeyUuid] = queryKey
      return itineraryService.getItineraryByUUID(queryKeyUuid, true)
    },
    { enabled: false, refetchOnWindowFocus: false }
  )

  const handleGetItineraryWithMarker = async (uuid?: string) => {
    setCurrUuid(uuid || "")
    // Invalidate the queries with the updated query key
    await queryClient.invalidateQueries(['itinerary', currUuid])
    await refetchItineraryWithMarker()
  }

  // Mutations for create, update, and delete
  const createMutation = useMutation(
    (variables: { newItinerary: Itinerary }) =>
      itineraryService.createItinerary(variables.newItinerary),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['itineraries'])
      },
      onError: (error: Error) => {
        console.error("Error during createItinerary:", error.message)
      },
    })

  const updateMutation = useMutation(
    (variables: { id: number, updatedItinerary: Itinerary }) => itineraryService.updateItinerary(variables.id, variables.updatedItinerary), {
    onSuccess: () => {
      queryClient.invalidateQueries(['itineraries'])
    },
  })

  const deleteMutation = useMutation(itineraryService.deleteItinerary, {
    onSuccess: () => {
      queryClient.invalidateQueries(['itineraries'])
    },
  })

  const handleCreate = async (newItinerary: Itinerary): Promise<Itinerary> => {
    try {
      const createdItinerary = await createMutation.mutateAsync({
        newItinerary,
      })
      return createdItinerary as Itinerary // Explicitly cast to Itinerary
    } catch (error) {
      throw error
    }
  }

  const handleUpdate = async (id: number, updatedItinerary: Itinerary): Promise<Itinerary> => {
    try {
      const result = await updateMutation.mutateAsync({ id, updatedItinerary })
      return result as Itinerary
    } catch (error) {
      throw error
    }
  }

  const handleDelete = async (uuid: string) => {
    await deleteMutation.mutateAsync(uuid)
  }

  return {
    itineraries,
    isLoading,
    createItinerary: handleCreate,
    updateItinerary: handleUpdate,
    deleteItinerary: handleDelete,
    getItineraryWithMarker: handleGetItineraryWithMarker,
    itineraryWithMarker: itineraryWithMarker as Itinerary,
    isItineraryWithMarkerLoading
  }
}
