import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { User } from "@auth0/auth0-react"
import { AppRouteSingle, VesselSegment, userApi } from "./userService"

export interface Organization {
  id: string
  display_name: string
  name: string
  segments: VesselSegment[]
  default_segment: VesselSegment | null
}

export type AppRoutesFlattened = { [key: string]: AppRouteSingle }

export interface UserState {
  user?: User
  chosenOrganization?: Organization
  segments?: VesselSegment[]
  defaultSegment: VesselSegment | null
  appRoutes?: AppRouteSingle[]
  appRoutesFlattened?: AppRoutesFlattened
  permissions?: string[]
  organizations?: Organization[]
}



const initialState: UserState = {
  user: undefined,
  chosenOrganization: undefined,
  segments: undefined,
  defaultSegment: null,
  appRoutes: undefined,
  appRoutesFlattened: undefined,
  permissions: undefined,
  organizations: undefined
}

// TODO: Test this
export function flattenAppRoutes(rootMenu: AppRouteSingle[] | undefined) {
  if (!rootMenu) {
    return
  }
  const appRoutes = {} as AppRoutesFlattened

  function flatten(menu: AppRouteSingle[]) {
    for (let item of menu) {
      appRoutes[item.path] = {
        path: item.path,
        name: item.name,
        permission: item.permission,
        reportId: item.reportId,
        groupId: item.groupId,
        pageName: item.pageName,
        isPage: item.isPage,
      }
      if (item.subMenus) {
        flatten(item.subMenus)
      }
    }
  }

  flatten(rootMenu)
  return appRoutes
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authenticateUserSuccess: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setChosenOrganization: (state, action: PayloadAction<Organization>) => {
      state.chosenOrganization = action.payload
    },
    setDefaultSegment: (state, action: PayloadAction<VesselSegment>) => {
      state.defaultSegment = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getUserOrganizations.matchFulfilled,
        (state, { payload }) => {
          const storedDefaultSegment: VesselSegment | null = JSON.parse(
            localStorage.getItem("defaultSegment") || "null"
          )
          if (payload && payload.length > 0) {
            state.organizations = payload
            state.chosenOrganization = payload[0]
            state.defaultSegment = storedDefaultSegment ? storedDefaultSegment : payload[0].default_segment
          }
        }
      )
      .addMatcher(
        userApi.endpoints.getAppRoutes.matchFulfilled,
        (state, { payload }) => {
          state.appRoutes = payload
          state.appRoutesFlattened = flattenAppRoutes(payload)
        }
      )
      .addMatcher(
        userApi.endpoints.getUserPermissions.matchFulfilled,
        (state, { payload }) => {
          state.permissions = payload
        }
      )
    // .addMatcher()
    // .addMatcher()
  },
})

export const {
  authenticateUserSuccess,
  setChosenOrganization,
  setDefaultSegment,
} = userSlice.actions

export default userSlice.reducer

// Save object to local storage
export const SaveToLocalStorage = (key: string, obj: any): void => {
  localStorage.setItem(key, JSON.stringify(obj))
}

// Retrieve object from local storage
export const GetFromLocalStorage = (key: string): any => {
  let storedItem = localStorage.getItem(key)
  try {
    storedItem = JSON.parse(storedItem || "")
  } catch (error) {
    // not JSON
  }
  return storedItem
}

