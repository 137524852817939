import Breadcrumbs from "components/Common/Breadcrumbs"
import React, { useState, useEffect } from "react"
import { Button, Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import SortableLists from "./Sortable/SidebarLists"
import MenuForm from "./Canvas/MenuForm"
import { SidebarItem } from "../shared/types"
import EditRearrange from "./Canvas/EditRearrange"
import { useGetPermissions } from "../hooks/useGetPermissions"
import { PARENT_MENU_PLACEHOLDER } from "../shared/libs"

function AdminSidebarWrapper() {
  const [show, setShow] = useState<boolean>(false)
  const [showRe, setShowRe] = useState<boolean>(false)
  const [items, setItems] = useState<SidebarItem[]>([])
  const [inActive, setInactive] = useState<boolean>(true)
  const { getPermissionsFromUser, permissionList, isLoading } =
    useGetPermissions()

  const handleClose = () => {
    setShow(false)
  }
  const handleCloseRe = () => {
    setShowRe(false)
  }

  const handleChange = (tempItems: SidebarItem[]) => {
    setItems(tempItems)
  }

  const handleButtonSaveState = () => {
    setInactive(false)
  }

  /* Set permissions */
  useEffect(() => {
    getPermissionsFromUser()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Sidebar Configuration Management | Market Manager Platform
        </title>
      </Helmet>
      <Container fluid className="my-3 px-4">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <Breadcrumbs title="Sidebar" breadcrumbItem="Sidebar Configuration" />
          <div className="d-flex flex-row gap-2">
            <Button
              variant="secondary"
              className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
              disabled={inActive}
              onClick={() => setShowRe(true)}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => setShow(true)}
              className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
            >
              <i className="ti-plus"></i>Add Menu
            </Button>
          </div>
        </div>
        {isLoading ? (
          "Loading"
        ) : permissionList && permissionList.length > 0 ? (
          <>
            <SortableLists
              onChange={handleChange}
              isButtonActive={handleButtonSaveState}
              permissions={permissionList as string[]}
            />
            {/* Offcanvas */}
            <MenuForm
              show={show}
              handleClose={handleClose}
              parentId={null}
              parentName={null}
              parentPath={PARENT_MENU_PLACEHOLDER}
              permissions={permissionList as string[]}
            />
            {/* Modal */}
            <EditRearrange
              show={showRe}
              handleClose={handleCloseRe}
              items={items}
            />
          </>
        ) : (
          "Cant get user permissions"
        )}
      </Container>
    </React.Fragment>
  )
}

export default AdminSidebarWrapper
