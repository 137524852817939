import {
  Itineraries,
  Itinerary,
  Markers,
} from "modules/PortPredictor/hooks/useItineraryAPI"
import { PortPredictorJourneyMarker } from "modules/PortPredictor/portPredictorReducer"
import {
  GetHighestItinearyId,
  GetHighestItinerarySeqId,
  GetNextSelectedId,
  MergeItineraries,
} from "./Utilities"

export const DUPLICATE_SELECTED_ITINERARY = "DUPLICATE_SELECTED_ITINERARY"
export const NEW_ITINERARY = "NEW_ITINERARY"
export const OPEN_SELECTED_SAVED_ITINERARY = "OPEN_SELECTED_SAVED_ITINERARY"
export const REMOVE_OPENED_ITINERARY = "REMOVE_OPENED_ITINERARY"
export const RENAME_SELECTED_ITINERARY = "RENAME_SELECTED_ITINERARY"
export const SAVE_SELECTED_ITINERARY = "SAVE_SELECTED_ITINERARY"
export const SET_ITINERARIES = "SET_ITINERARIES"
export const SET_SELECTED_ITINERARY_ID_AND_OPEN =
  "SET_SELECTED_ITINERARY_ID_AND_OPEN"
export const UPDATE_ITINERARY = "UPDATE_ITINERARY"

export interface PortPredictorMarkerBase {
  id: number
}
export interface ItineraryState {
  itineraries: Itineraries
  selectedId: number
}

const addNewItinerary = (itineraries: Itineraries): Itineraries => {
  const customFilter = (item: Itinerary): boolean | undefined => item.isNew
  const highestIndexForId = GetHighestItinearyId(itineraries, customFilter)
  const highestIndexForName = GetHighestItinearyId(itineraries)

  const suffix = highestIndexForName ? highestIndexForName + 1 : 1
  const newId = highestIndexForId ? highestIndexForId + 1 : 1
  const newName = `Tab ${suffix}`

  const result: Itineraries = [
    ...(itineraries ?? []),
    {
      name: newName,
      id: newId,
      uuid: "",
      isModified: true,
      isOpened: true,
      isNew: true,
      isEditMode: true,
      openSeqId: GetHighestItinerarySeqId(itineraries) + 1,
      clientVersion: "1.0",
      markers: { data: [] as PortPredictorJourneyMarker[], meta: {} } as Markers
    },
  ]
  return result
}

export const InitialItineraryState: ItineraryState = {
  itineraries: [{
    name: "Loading...",
    id: -1,
    uuid: "",
    isModified: false,
    isOpened: true,
    isNew: false,
    openSeqId: -1,
    clientVersion: "1.0",
    markers: { data: [] as PortPredictorJourneyMarker[], meta: {} } as Markers
  } as Itinerary],
  selectedId: -1,
}

export type ItineraryActions =
  | {
    type: typeof DUPLICATE_SELECTED_ITINERARY
    payload: { prev: number, updated: number }
  }
  | {
    type: typeof NEW_ITINERARY
  }
  | {
    type: typeof OPEN_SELECTED_SAVED_ITINERARY
    payload: Itinerary
  }
  | {
    type: typeof REMOVE_OPENED_ITINERARY
    payload: { id: number, addNew: boolean }
  }
  | {
    type: typeof RENAME_SELECTED_ITINERARY
    payload: { id: number, name: string } | Itinerary
  }
  | {
    type: typeof SAVE_SELECTED_ITINERARY
    payload: { prev: number, updated: number, updateKeys: (Partial<Record<keyof Itinerary, any>>) | undefined }
  }
  | {
    type: typeof SET_ITINERARIES
    payload: Itinerary[] | undefined
  }
  | {
    type: typeof SET_SELECTED_ITINERARY_ID_AND_OPEN
    payload: number
  }
  | {
    type: typeof UPDATE_ITINERARY
    payload: Partial<Record<keyof Itinerary, any>>
  }

const updateItineraryById = (
  itineraries: Itineraries,
  idToUpdate: number,
  modifiedKeys: Partial<Record<keyof Itinerary, any>>
): Itineraries => {
  // Check if itineraries is defined and not null
  if (itineraries) {
    const updatedItineraries = itineraries.map((itinerary) =>
      itinerary.id === idToUpdate
        ? { ...itinerary, ...modifiedKeys }
        : itinerary
    )
    return updatedItineraries
  }

  // If itineraries is undefined, return the original value
  return itineraries
}

export const ItineraryReducer = (
  state: ItineraryState,
  action: ItineraryActions
): ItineraryState => {
  switch (action.type) {
    case DUPLICATE_SELECTED_ITINERARY: {
      const result: ItineraryState = {
        itineraries: updateItineraryById(
          state.itineraries,
          action.payload.prev,
          {}
        ),
        selectedId: action.payload.updated,
      }
      return result
    }
    case NEW_ITINERARY: {
      const newItineraries = addNewItinerary(state.itineraries)
      const result: ItineraryState = {
        itineraries: [...(newItineraries ?? [])],
        selectedId: newItineraries![newItineraries!.length - 1].id || 1,
      }
      return result
    }
    case OPEN_SELECTED_SAVED_ITINERARY: {
      const updated = updateItineraryById(
        state.itineraries,
        action.payload.id!,
        {
          isOpened: true,
          openSeqId: GetHighestItinerarySeqId(state.itineraries) + 1,
          markers: action.payload.markers,
          isModified: false,
        }
      )
      return {
        itineraries: updated,
        selectedId: action.payload.id || undefined,
      } as ItineraryState
    }
    case REMOVE_OPENED_ITINERARY: {
      let updatedItineraries = updateItineraryById(
        state.itineraries,
        action.payload.id,
        { isOpened: false }
      )
      let nextSelectedId = GetNextSelectedId(state.itineraries, action.payload.id)

      if (nextSelectedId === action.payload.id) {
        if (action.payload.addNew) {
          updatedItineraries = addNewItinerary(updatedItineraries)
        }
        nextSelectedId =
          updatedItineraries![updatedItineraries!.length - 1].id || 1
      }
      const result: ItineraryState = {
        ...state,
        itineraries: updatedItineraries,
        selectedId: nextSelectedId,
      }
      return result
    }
    case RENAME_SELECTED_ITINERARY: {
      const result = {
        ...state,
        itineraries: updateItineraryById(
          state.itineraries,
          action.payload.id || 0,
          { name: action.payload.name, isOpened: false }
        ),
      }
      return result
    }
    case SAVE_SELECTED_ITINERARY: {
      const result: ItineraryState = {
        itineraries: updateItineraryById(
          state.itineraries,
          action.payload.prev,
          { isOpened: false, isNew: false, isModified: false, ...action.payload.updateKeys }
        ),
        selectedId: action.payload.updated,
      }
      return result
    }
    case SET_ITINERARIES: {
      const openedItineraries = state.itineraries
        ? state.itineraries.filter((i) => i.isOpened)
        : []
      const payloadItineraries = action.payload || []
      const result = {
        ...state,
        itineraries: MergeItineraries(payloadItineraries, openedItineraries),
      }
      return result
    }
    case SET_SELECTED_ITINERARY_ID_AND_OPEN: {
      const result: ItineraryState = {
        itineraries: updateItineraryById(state.itineraries, action.payload, {
          isOpened: true,
        }),
        selectedId: action.payload,
      }
      return result
    }
    case UPDATE_ITINERARY: {
      const result: ItineraryState = {
        ...state,
        itineraries: updateItineraryById(state.itineraries, action.payload.id, {
          ...action.payload,
        }),
      }
      return result
    }
    default:
      return state
  }
}
