import AdminSidebarWrapper from "./components/AdminSidebarWrapper"

const settings = {
    routes : [
        {
            path: "/admin/sidebar",
            permissionForPage: "read:sidebar-admin",
            component: AdminSidebarWrapper
        },     
    ]
}

export default settings