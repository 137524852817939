import L, { LatLngExpression, Map as LeafletMap } from "leaflet"
import "leaflet.fullscreen"
import Line from "modules/MapComponent/components/map/Line"
import { Marker } from "modules/MapComponent/components/map/MarkerNew"
import { fixAntimeridian } from "modules/MapComponent/components/map/positions"
import { isValidArray } from "modules/MapComponent/utils/data"
import { VesselPositions } from "modules/Prevetting/hooks/useFetchVesselPositions"
import { VesselStay } from "modules/Prevetting/hooks/useFetchVesselStays"
import {
  VesselPortStay,
  VesselPosition,
} from "modules/Prevetting/hooks/usePrevetAPI"
import moment from "moment"
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react"
import { Accordion, Table } from "react-bootstrap"
import {
  CircleMarker,
  MapContainer,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet"
import { ReactComponent as LocationIcon } from "../../../assets/images/icons/marker-location.svg"
import { getMarkersForFuturePorts } from "../helpers/mapHelpers"
import { PortPredictorVesselStateDataWithMajorCargo } from "../hooks/usePortPredictorAPI"
import { PortPredictorJourneyState } from "../portPredictorReducer"
import classes from "../styles/port-predictor-map.module.scss"
import { MarkerPoint } from "./PortPredictorMain"

export function PortPredictorMap({
  chosenVesselStateData,
  futurePath,
  journeyMarkerState,
  map,
  setMap,
  vesselStaysData,
  vesselPositions,
}: {
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo
  futurePath: MarkerPoint[] | undefined
  journeyMarkerState: PortPredictorJourneyState
  map: LeafletMap | null
  setMap: Dispatch<SetStateAction<L.Map | null>>
  vesselStaysData: VesselPortStay[] | VesselStay[]
  vesselPositions: VesselPosition[]
}) {
  const initialCenter = useMemo(
    () => [
      chosenVesselStateData.last_pos_lat,
      chosenVesselStateData.last_pos_lon,
    ],
    []
  ) as LatLngExpression

  // Note the fixAntimeridian function edits the vesselStaysData IN PLACE
  // So if you console.log vesselStaysData here it will always show the meridian was fixed
  // e.g. Singapore will have a lon of -256.03511000000003 even tho it was not fixed until
  // getLastFivePortStay function was ran
  const lastFivePortStays = getLastFivePortStay({
    vesselStaysData,
    chosenVesselStateData,
  })

  const filteredPositions = useMemo(() => {
    return getPositionsFilteredByLastThreePortStays({
      vesselPositions: fixAntimeridian(vesselPositions),
      vesselStaysData,
    }).reverse()
  }, [vesselPositions, vesselStaysData])

  const displayMap = useMemo(() => {
    return (
      <MapContainer
        whenCreated={setMap}
        center={initialCenter}
        zoom={4}
        style={{ height: "50vh", width: "100%" }}
      >
        <div className={classes.Legend}>
          <div className="d-flex align-items-center">
            <img
              className={`${classes.Legend_Icon} me-1`}
              src="/images/marker-circle.svg"
              alt="Icon"
            />
            <div>Current position</div>
          </div>
          <div className="d-flex align-items-center">
            <LocationIcon
              className={`${classes.Legend_Icon} me-1`}
              fill="#999"
            />
            <div>Prior port</div>
          </div>
          <div className="d-flex align-items-center">
            <LocationIcon
              className={`${classes.Legend_Icon} me-1`}
              fill="#2a82cb"
            />
            <div>Future port</div>
          </div>
          <div className="d-flex align-items-center">
            <div
              className={`${classes.Legend_Line} ${classes.Legend_Line_Ballast} me-2`}
            ></div>
            <div>Ballast leg</div>
          </div>
          <div className="d-flex align-items-center">
            <div
              className={`${classes.Legend_Line} ${classes.Legend_Line_Laden} me-2`}
            ></div>
            <div>Laden leg</div>
          </div>
          <div className="d-flex align-items-center">
            <div className={`${classes.Legend_FutureLine} me-2`}></div>
            <div>Future leg</div>
          </div>
        </div>
        <div className={classes.PortStaysAccordion}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Last 5 Port Stays</Accordion.Header>
              <Accordion.Body style={{ padding: "0.5rem 0.5rem 0rem 0.5rem" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Departure</th>
                      <th>Port</th>
                      <th>Act.</th>
                      <th>Most Likely Cargo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastFivePortStays?.map((vesselStay, idx) => {
                      return (
                        <tr key={vesselStay.date_to}>
                          <td>
                            {vesselStay.date_to &&
                              moment(vesselStay.date_to).format("D MMM 'YY")}
                          </td>
                          <td>{vesselStay.port}</td>
                          <td>{getMarkerActivityForPortStay(vesselStay)}</td>
                          <td>{vesselStay.cargo}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <TileLayer
          url="https://mts1.google.com/vt/lyrs=s,h&hl=en&src=app&x={x}&y={y}&z={z}&s=G"
          attribution="&copy; Esri"
        />

        {isValidArray(filteredPositions) && (
          <>
            {getVesselLegs(filteredPositions).map((positions) => {
              // Take the state of the vessel at the start of the leg to determine color
              // This is impt for connecting segments, eg. vessel may be laden at start of by ballast at end
              // Therefore we use the color at start
              const color =
                positions[0].draft_laden === true ? "#99b0ff" : "#ff5c29"

              const key = `${positions[0].pos_date}_${
                positions[1] ? positions[1].pos_date : ""
              }`
              return (
                <Line
                  points={positions}
                  markerProps={{
                    minRadius: 1,
                    maxRadius: 15,
                  }}
                  tooltip={undefined}
                  pathOptions={{
                    color,
                  }}
                  key={key}
                />
              )
            })}
            <>
              {filteredPositions.map((pos) => {
                return (
                  <CircleMarker
                    radius={3}
                    stroke={false}
                    fillOpacity={0.6}
                    fillColor={pos.draft_laden === true ? "#99b0ff" : "#ff5c29"}
                    key={pos.pos_date}
                    center={{ lat: pos.lat, lng: pos.lon }}
                  >
                    <Popup closeButton={true}>
                      <>
                        {pos &&
                          pos.pos_date &&
                          moment(pos.pos_date).format("DD-MM-YYYY")}{" "}
                        <br />
                        Speed : {pos.sog} (Kn) <br />
                        Destination : {pos.master_destination} <br />
                        Draft Ratio : {pos.draft_ratio} <br />
                        ETA : {pos.master_eta}
                      </>
                    </Popup>
                  </CircleMarker>
                )
              })}
            </>
          </>
        )}

        {futurePath && isValidArray(futurePath) && (
          <Line
            key="futurePath"
            points={fixAntimeridian(futurePath)}
            markerProps={{
              minRadius: 1,
              maxRadius: 15,
            }}
            pathOptions={{
              color: "chartreuse",
              dashArray: "4, 4", // This creates a dotted line
            }}
            tooltip={undefined}
          />
        )}
        {journeyMarkerState &&
          journeyMarkerState.length > 1 &&
          getMarkersForFuturePorts(journeyMarkerState).map((marker) => {
            return (
              <Marker
                key={marker.key}
                lat={marker.lat}
                lon={marker.lon}
                iconType="futurePort"
              >
                <Popup closeButton={true}>
                  Port {marker.portNumber + 1}: {marker.port}
                </Popup>
              </Marker>
            )
          })}
        {lastFivePortStays &&
          lastFivePortStays.map((priorPort, idx) => {
            return (
              <Marker
                key={priorPort.lon + priorPort.date_from}
                lon={priorPort.lon}
                lat={priorPort.lat}
                iconType="priorPort"
              >
                <Popup>
                  Prior Port: {priorPort.port}
                  <br />
                  Est. Arrival:{" "}
                  {moment(priorPort.date_from).format("D MMM 'YY")}
                  <br />
                  Est. Departed: {moment(priorPort.date_to).format("D MMM 'YY")}
                </Popup>
              </Marker>
            )
          })}
        {chosenVesselStateData && (
          <Marker
            lon={chosenVesselStateData.last_pos_lon}
            lat={chosenVesselStateData.last_pos_lat}
            iconType="currPos"
            openPopup
          >
            <Popup closeButton={true}>
              <div>
                Last Updated:{" "}
                {chosenVesselStateData.last_pos_time &&
                  moment
                    .utc(chosenVesselStateData.last_pos_time)
                    .local()
                    .format("D MMM YYYY HH:mm [GMT]Z")}{" "}
              </div>
              <div>Status: {chosenVesselStateData.last_nav_sta_desc}</div>

              <div>
                Prior Port: {lastFivePortStays && lastFivePortStays[0]?.port}
              </div>
              <div>
                Master destination:{" "}
                {chosenVesselStateData.last_master_destination_desc} (
                {chosenVesselStateData.last_master_destination})
              </div>
              {chosenVesselStateData.last_sog !== 0 && (
                <div>Speed Over Ground: {chosenVesselStateData.last_sog}</div>
              )}
            </Popup>
          </Marker>
        )}
        <FullscreenControl />
      </MapContainer>
    )
  }, [
    /** futurePath does not change on every update of journeyMarkerState
     * useFetchFuturePath is a react-query calling the distance calculator only
     * when a user changes a port in journeyMarkerState. If journeyMarkerState
     * updates with same ports, react-query returns the exact reference to the data object
     */
    futurePath,
    filteredPositions,
    chosenVesselStateData,
  ])

  return <div className={classes.Map}>{displayMap}</div>
}

export function FullscreenControl(props: any) {
  const map = useMap()
  const ctrl = useRef((L.control as any).fullscreen(props))

  useEffect(() => {
    ctrl.current.addTo(map)

    return () => {
      ctrl.current.remove()
      ctrl.current.link.remove()
    }
  })

  return null
}

export function getLastFivePortStay({
  vesselStaysData,
  chosenVesselStateData,
}: {
  vesselStaysData: VesselPortStay[] | VesselStay[] | undefined
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo
}) {
  // Data arrives sorted from most recent date to farthest back date
  if (!vesselStaysData || vesselStaysData.length < 1) {
    return
  }

  const result = [] as VesselStay[]

  for (let i = 0; i < vesselStaysData.length; i++) {
    if (vesselStaysData[i].port) {
      result.push(vesselStaysData[i] as VesselStay)
    }
    if (vesselStaysData.length > 5 && result.length === 5) {
      break
    }
    if (
      vesselStaysData.length < 5 &&
      result.length === vesselStaysData.length
    ) {
      break
    }
  }

  const fixed = fixAntimeridian([
    {
      lat: chosenVesselStateData.last_pos_lat,
      lon: chosenVesselStateData.last_pos_lon,
    },
    ...result,
  ]) as VesselStay[]

  return fixed.slice(1)
}

function getMarkerActivityForPortStay(vesselStay: VesselStay) {
  if (vesselStay.yard_activity) {
    return "Y"
  }
  if (vesselStay.activity === "L") {
    return "L"
  }
  if (vesselStay.activity === "D") {
    return "D"
  }
  if (vesselStay.activity === "F") {
    return "B"
  }
}

export function getVesselLegs(vesselPositions: VesselPositions[]) {
  const lineSegments: VesselPositions[][] = []
  let currentSegment: VesselPositions[] = []
  let currDraftLaden = vesselPositions[0].draft_laden

  for (let pos of vesselPositions) {
    if (pos.draft_laden === currDraftLaden) {
      currentSegment.push(pos)
      continue
    }
    if (pos.draft_laden !== currDraftLaden) {
      currDraftLaden = pos.draft_laden
      lineSegments.push(currentSegment)
      const endPosOfPrevSegment = currentSegment[currentSegment.length - 1]
      const connectingSegment = [endPosOfPrevSegment, pos]
      lineSegments.push(connectingSegment)
      currentSegment = []
      currentSegment.push(pos)
    }
  }
  // Push the remaining leg/segment which was not pushed in the second if clause
  lineSegments.push(currentSegment)
  return lineSegments
}

export function getPositionsFilteredByLastThreePortStays({
  vesselPositions,
  vesselStaysData,
}: {
  vesselPositions: VesselPositions[]
  vesselStaysData: VesselStay[]
}) {
  if (vesselStaysData.length === 0) {
    // If no vessel stays data, return all the positions
    return vesselPositions
  }

  let startIndex
  if (vesselStaysData.length === 1) {
    startIndex = 0
  } else if (vesselStaysData.length === 2) {
    startIndex = 1
  } else {
    startIndex = 2 // Always return index 2 for length 3 or more
  }

  const startEntry = vesselStaysData[startIndex]
  if (!startEntry || !startEntry.date_from) {
    // If no valid start entry or date_from, return all positions
    return vesselPositions
  }

  const startDate = moment(startEntry.date_from)

  const endDate = moment(Date.now())
  const result = vesselPositions.filter((pos) => {
    const posDate = moment(pos.pos_date)

    return posDate.isBetween(startDate, endDate)
  })

  return result
}
