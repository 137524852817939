import BigNumber from "bignumber.js"
import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorJourneyState,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  PortType,
  VesselStateLadenOrBallast,
} from "modules/PortPredictor/portPredictorReducer"
import moment from "moment"
import { VesselSpeedDataWithAverages } from "../PortPredictorDeparture/helpers"


interface RecalculateTimelineProps {
  journeyMarkerState: PortPredictorJourneyState
  startFromMarker: PortPredictorJourneyMarker
  speedDataWithAvg?: VesselSpeedDataWithAverages
}
interface RecalculateTimelineFromDeparture {
  journeyMarkerState: PortPredictorJourneyState
  startFromMarker: PortPredictorMarkerDeparture
}

interface RecalculateTimelineFromArrival {
  journeyMarkerState: PortPredictorJourneyState
  updatedArrivalMarker: PortPredictorMarkerArrival
  speedDataWithAvg?: VesselSpeedDataWithAverages
}

export function recalculateTimelineAfterDelete({
  deletedMarker,
  journeyMarkerState
}: {
  deletedMarker: PortPredictorJourneyMarker,
  journeyMarkerState: PortPredictorJourneyState
}) {
  // Delete button available on Arrival markers only, therefore prev marker will always be a Departure marker
  const prevMarker = journeyMarkerState[deletedMarker.id - 1]
  if (!prevMarker || prevMarker.markerType !== MarkerType.Departure) {
    return
  }

  // A(0) -- D(1) -- A(2) -- D(3) -- A(4)
  // If we delete A(2), we will delete D(3), therefore tail is A(4)
  const nextMarker = journeyMarkerState[deletedMarker.id + 2]
  const sliceUpToButNotIncluding = deletedMarker.id
  const head = journeyMarkerState.slice(0, sliceUpToButNotIncluding)

  // D(0) -- A(1)
  // If we delete A(1), we no longer know the distance from D(0) to A(1)
  prevMarker.daysToArrival = undefined
  prevMarker.distanceToArrival = undefined
  head[head.length - 1] = prevMarker

  if (!nextMarker) {
    return head
  }

  const tail = journeyMarkerState.slice(nextMarker.id)
  const newState = [...head, ...tail].map((marker, idx) => {
    // Reorder the markers
    return {
      ...marker,
      id: idx
    }
  })

  return recalculateFromDeparture({
    journeyMarkerState: newState,
    startFromMarker: prevMarker
  })
}

export function recalculateTimeline({
  journeyMarkerState,
  startFromMarker,
  speedDataWithAvg
}: RecalculateTimelineProps) {
  const prevMarker = journeyMarkerState[journeyMarkerState.length - 1] as
    | PortPredictorJourneyMarker
    | undefined
  if (startFromMarker.id === 0 && journeyMarkerState.length === 1) {
    // if the first startFromMarker has been modified
    return [startFromMarker]
  }
  if (prevMarker && startFromMarker.id > prevMarker.id) {
    // Appending a startFromMarker to the end of the state
    return [startFromMarker]
  }
  if (startFromMarker.markerType === MarkerType.Departure) {
    return recalculateFromDeparture({
      journeyMarkerState,
      startFromMarker: startFromMarker as PortPredictorMarkerDeparture,
    })
  }
  if (startFromMarker.markerType === MarkerType.Arrival) {
    return recalculateFromArrival({
      journeyMarkerState,
      updatedArrivalMarker: startFromMarker as PortPredictorMarkerArrival,
      speedDataWithAvg
    })
  }
}

function recalculateFromArrival({
  journeyMarkerState,
  updatedArrivalMarker,
  speedDataWithAvg
}: RecalculateTimelineFromArrival) {
  // If original timeline is D1 --- A1 --- D1 --- A1
  // this creates a timeline of D2 --- A2 --- D1 --- A1 ...
  const newTimeline: PortPredictorJourneyMarker[] = [
    {
      ...updatedArrivalMarker,
    },
    ...journeyMarkerState.slice(updatedArrivalMarker.id + 1),
  ]

  // Starts from D1
  // e.g for timeline A1 --- (D1) --- (A1), results will have [D1, A1]

  for (let i = 0; i < newTimeline.length; i++) {
    const curr = newTimeline[i]
    const next = newTimeline[i + 1]
    const indexOfMarkerToReplace = i + 1
    if (next && next.markerType === MarkerType.Departure) {
      const currRef = curr as PortPredictorMarkerArrival

      const vesselSpeedSelection = currRef.vesselState === VesselStateLadenOrBallast.Laden ? speedDataWithAvg?.laden : speedDataWithAvg?.ballast
      const monthKey = vesselSpeedSelection?.defaultMonthsKey
      const vesselSpeedSelected = vesselSpeedSelection && monthKey ? vesselSpeedSelection[monthKey] : undefined

      let newArrivalDate
      if (currRef.turnAroundTime) {
        const portTurnAroundTimeRoundedUp = BigNumber(currRef.turnAroundTime).decimalPlaces(0, BigNumber.ROUND_CEIL).toNumber()
        newArrivalDate = moment(currRef.arrivalDate)
          .add(portTurnAroundTimeRoundedUp, "days")
          .format("YYYY-MM-DD")
      }
      const updatedDeparture: PortPredictorMarkerDeparture = {
        ...next,
        date: newArrivalDate,
        posWkt: currRef.port && currRef.port.pos_wkt,
        port: currRef.port,
        cargo: currRef.cargo,
        cargoGroup: currRef.cargoGroup,
        vesselState: currRef.vesselState,
        vesselSpeedSelected: vesselSpeedSelected?.avg 
      }
      newTimeline[indexOfMarkerToReplace] = updatedDeparture
    }
    if (next && next.markerType === MarkerType.Arrival) {
      const currRef = curr as PortPredictorMarkerDeparture
      const updatedArrival: PortPredictorMarkerArrival = {
        ...next,
        arrivalDate: moment(currRef.date)
          .add(currRef.daysToArrival, "days")
          .format("YYYY-MM-DD"),
        portType: currRef.vesselState === VesselStateLadenOrBallast.Laden ? PortType.Discharge : PortType.LoadWithLaycan,
        vesselState: currRef.vesselState === VesselStateLadenOrBallast.Laden ? VesselStateLadenOrBallast.Ballast : VesselStateLadenOrBallast.Laden,
        cargo: currRef.vesselState === VesselStateLadenOrBallast.Laden ? currRef.cargo : undefined,
        cargoGroup: currRef.vesselState === VesselStateLadenOrBallast.Laden ? currRef.cargoGroup : undefined,
      }
      newTimeline[indexOfMarkerToReplace] = updatedArrival
    }
  }

  const departureMarkerBeforeUpdatedArrival = journeyMarkerState[
    updatedArrivalMarker.id - 1
  ] as PortPredictorMarkerDeparture | undefined
  if (departureMarkerBeforeUpdatedArrival) {
    return [
      {
        ...departureMarkerBeforeUpdatedArrival,
        daysToArrival: updatedArrivalMarker.daysFromDeparture,
        distanceToArrival: updatedArrivalMarker.distanceFromDeparture,
      },
      ...newTimeline,
    ]
  }

  return [...newTimeline]
}

function recalculateFromDeparture({
  journeyMarkerState,
  startFromMarker,
}: RecalculateTimelineFromDeparture) {
  const newTimeline: PortPredictorJourneyMarker[] = [
    { ...startFromMarker },
    ...journeyMarkerState.slice(startFromMarker.id + 1),
  ]

  for (let i = 0; i < newTimeline.length; i++) {
    const curr = newTimeline[i]
    const next = newTimeline[i + 1]
    const indexOfMarkerToReplace = i + 1
    if (next && next.markerType === MarkerType.Arrival) {
      const currRef = curr as PortPredictorMarkerDeparture

      // portType of arrival marker will be inverse of the current vesselState unless the preserveFollowingPortType prop is set to true
      const newPortType = currRef.preserveFollowingPortType === true ? next.portType :
        currRef.vesselState === VesselStateLadenOrBallast.Ballast ? PortType.LoadWithLaycan : PortType.Discharge

      const newArrival: PortPredictorMarkerArrival = {
        ...next,
        vesselState: newPortType === PortType.Other ? currRef.vesselState : // Retain vesselState if arrivalMarker is a Other/Bunker
          currRef.vesselState === VesselStateLadenOrBallast.Ballast ? VesselStateLadenOrBallast.Laden : VesselStateLadenOrBallast.Ballast, // Otherwise if vessel was Ballast before, now it's at newPortType: LoadPort and vesselState is Laden (or vice versa)
        portType: newPortType,
        arrivalDate: moment(currRef.date)
          .add(currRef.daysToArrival, "days")
          .format("YYYY-MM-DD"),
      }
      newTimeline[indexOfMarkerToReplace] = newArrival
    }
    if (next && next.markerType === MarkerType.Departure) {
      const currRef = curr as PortPredictorMarkerArrival
      const newDeparture: PortPredictorMarkerDeparture = {
        ...next,
        vesselState: currRef.vesselState,
        date: moment(currRef.arrivalDate)
          .add(currRef.turnAroundTime, "days")
          .format("YYYY-MM-DD"),
      }
      newTimeline[indexOfMarkerToReplace] = newDeparture
    }
  }

  return newTimeline
}
