import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
interface PowerBiEmbedToken {
  "@odata.context": string
  token: string
  tokenId: string
  expiration: string
}

interface UsePowerBiEmbedTokenProps {
  groupId: string
  reportId: string
  permission: string
  pageName: string
  isPreview: boolean
}

export function usePowerBiEmbedToken(props: UsePowerBiEmbedTokenProps) {
  const request = useAxiosRequest()
  const { groupId, reportId, permission, pageName, isPreview } = props

  const [biToken, setBiToken] = useState<PowerBiEmbedToken | undefined>()
  const [tokenLoading, setTokenLoading] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    let unmounted = false

    if (!unmounted) {
      setTokenLoading(true)
    }

    async function fetchToken() {
      try {
        const responseBody = await request<any, PowerBiEmbedToken>({
          signal: controller.signal,
          url: `${BASE_URL}/powerbi/embed-token`,
          method: "POST",
          params: {
            group: groupId,
            report: reportId,
            pageName: pageName,
          },
          data: {
            permission,
            isPreview,
          },
        })

        if (!unmounted && responseBody) {
          setBiToken(responseBody.data)
        }
      } catch (err: unknown) {
        if (unmounted) {
          return
        }
        const message = `Unable to get access to PowerBi.`
        if (axios.isAxiosError(err)) {
          toast.warn(message, {
            position: "bottom-right",
            autoClose: 8000,
          })
        }
      } finally {
        setTokenLoading(false)
      }
    }

    if (!biToken) {
      fetchToken()
    }

    return () => {
      unmounted = true
      controller.abort()
    }
  }, [])

  return {
    token: biToken,
    tokenLoading,
  }
}
