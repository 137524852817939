import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { useQueryClient } from "@tanstack/react-query"

interface useDeleteMenuArgs {
  handleDeleteMenu?: (id: string) => void
}

export function useDeleteMenu({ handleDeleteMenu }: useDeleteMenuArgs) {
  const request = useAxiosRequest()
  const queryClient = useQueryClient()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  async function deleteMenu(id: string) {
    try {
      setIsDeleting(true)
      await request<any, unknown>({
        url: "api/v1/sidebar/single",
        method: "DELETE",
        params: { id },
      })

      if (handleDeleteMenu) {
        handleDeleteMenu(id)
      }

      toast.success("Menu deleted successfully", {
        position: "bottom-right",
        autoClose: 8000,
      })
      queryClient.invalidateQueries(["sidebarItems"])
    } catch (err: unknown) {
      let message = "Error deleting menu"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      if (isMounted.current) {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      if (isMounted.current) {
        setIsDeleting(false)
      }
    }
  }
  return { deleteMenu, isDeleting }
}
