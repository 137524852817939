import React, { useState, useEffect } from "react"
import { useGetSidebarItems } from "../../hooks/useGetSidebarItems"
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { Accordion, Spinner } from "react-bootstrap"
import SortableItem from "./SortableItem"
import SearchSidebar from "../Selects/SearchSidebar"
import { SidebarItem, SidebarListProps } from "../../shared/types"
import { flattenSidebarItems } from "../../shared/helper"

const SidebarLists: React.FC<SidebarListProps> = ({
  onChange,
  isButtonActive,
  permissions,
}) => {
  const { sidebarItems, isLoading } = useGetSidebarItems()
  const [items, setItems] = useState<SidebarItem[]>([])
  const [search, setSearch] = useState<string | null>(null)

  /* set items */
  useEffect(() => {
    if (sidebarItems && sidebarItems.length > 0) {
      setItems(sidebarItems)
    }
  }, [sidebarItems])

  useEffect(() => {
    onChange(items)
  }, [items])

  const handleChange = (items: SidebarItem[]) => {
    onChange(items)
  }

  const onSearch = (filter: string | null) => {
    setSearch(filter)
  }

  /* For rearranging menu */
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id && items) {
      setItems((prevItems) => {
        if (!prevItems) return prevItems
        const oldIndex = prevItems.findIndex((item) => item.id === active.id)
        const newIndex = prevItems.findIndex((item) => item.id === over?.id)
        return arrayMove(prevItems, oldIndex, newIndex)
      })
      isButtonActive()
    }
  }

  const filteredItems = (items: SidebarItem[]): SidebarItem[] => {
    if (!search) return items

    const flattenedItems = flattenSidebarItems(items)
    const matchingIds = new Set(
      flattenedItems
        .filter((item) => item.id.toLowerCase().includes(search.toLowerCase()))
        .map((item) => item.id)
    )

    return items.filter((item) => {
      const flatItem = flattenSidebarItems([item])
      return flatItem.some((subItem) => matchingIds.has(subItem.id))
    })
  }

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading ...</span>
        </Spinner>
      ) : items?.length > 0 ? (
        <>
          <SearchSidebar items={items} onSearch={onSearch} />
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Accordion alwaysOpen>
              <SortableContext
                items={items.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                {filteredItems(items).map((item) => (
                  <SortableItem
                    key={item.id}
                    item={item}
                    onChange={handleChange}
                    isButtonActive={isButtonActive}
                    permissions={permissions}
                  />
                ))}
              </SortableContext>
            </Accordion>
          </DndContext>
        </>
      ) : (
        "No Sidebar Items detected"
      )}
      {/* Update changes modal */}
    </>
  )
}

export default SidebarLists
