import React, { useState, useEffect } from "react"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import "./assets/sortableItem.css"
import { GripVertical } from "lucide-react"
import { EllipsisVertical } from "lucide-react"
import { Dropdown, Card, Accordion } from "react-bootstrap"
import AccordionButton from "./AccordionButton"
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core"
import DeleteMenu from "../Canvas/DeleteMenu"
import MenuForm from "../Canvas/MenuForm"
import EditMenuForm from "../Canvas/EditMenuForm"
import { SidebarItem, SortableItemProps } from "../../shared/types"
import {
  PERMISSION_UPDATE_MENU_PBI,
  PERMISSION_DELETE_MENU_PBI,
} from "../../shared/libs"

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  onChange,
  isButtonActive,
  permissions,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
  }

  /* Close delete modal */
  const handleCloseDeleteModal = () => {
    setDeleteModalShow(false)
  }

  /* Close Form */
  const handleCloseForm = () => {
    setFormShow(false)
  }

  const handleAddSubMenu = () => {
    setFormShow(true)
  }

  const handleSubitemDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setCurrentSubItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over?.id)

        return arrayMove(items, oldIndex, newIndex)
      })
      isButtonActive()
    }
  }

  const [currentSubItems, setCurrentSubItems] = useState<SidebarItem[]>(
    item.subMenus || []
  )
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false)
  const [formShow, setFormShow] = useState<boolean>(false)
  const [editShow, setEditShow] = useState<boolean>(false)
  const [isPbiUpdate, setIsPbiUpdate] = useState<boolean>(false)
  const [isPbiDelete, setIsPbiDelete] = useState<boolean>(false)

  /* notify parent */
  useEffect(() => {
    onChange(currentSubItems)
  }, [currentSubItems])

  /* update submenus */
  useEffect(() => {
    setCurrentSubItems(item.subMenus || [])
  }, [item.subMenus])

  /* Check if admin is pbi only */
  useEffect(() => {
    if (permissions.includes(PERMISSION_UPDATE_MENU_PBI)) {
      setIsPbiUpdate(true)
    }
    if (permissions.includes(PERMISSION_DELETE_MENU_PBI)) {
      setIsPbiDelete(true)
    }
  }, [permissions])

  return (
    <>
      {/* Parent menu card */}
      <div
        className="sortable-item-div"
        ref={setNodeRef}
        style={style}
        {...attributes}
      >
        <Card className="w-100">
          <div className="d-flex flex-row align-items-center justify-content-between p-3 cursor-cursor">
            <div className="d-flex align-items-center gap-2">
              <AccordionButton
                eventKey={item.id}
                className="sortable-item-button sortable-button-common"
              />
              <p className="mb-0">{item.name}</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Dropdown>
                <Dropdown.Toggle
                  className="sortable-item-button sortable-button-common"
                  id="menu-options"
                >
                  <EllipsisVertical className="options-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {item.isPage ? (
                    <Dropdown.Item disabled className="bg-light">
                      Add submenu
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={handleAddSubMenu}>
                      Add submenu
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => setEditShow(true)}
                    disabled={isPbiUpdate && item.reportId === null}
                    className={
                      isPbiUpdate && item.reportId === null ? "bg-light" : ""
                    }
                  >
                    Edit menu
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setDeleteModalShow(true)}
                    disabled={isPbiDelete && item.reportId === null}
                    className={
                      isPbiUpdate && item.reportId === null ? "bg-light" : ""
                    }
                  >
                    Delete menu
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <button
                {...listeners}
                className="sortable-item-button drag-button"
              >
                <GripVertical className="drag-icon" />
              </button>
            </div>
          </div>
          {/* Submenu card */}
          <Accordion.Collapse eventKey={item.id}>
            <Card.Body>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleSubitemDragEnd}
              >
                <SortableContext
                  items={currentSubItems.map((subitem) => subitem.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {currentSubItems.map((subitem) => (
                    <SortableItem
                      key={subitem.id}
                      item={subitem}
                      onChange={onChange}
                      isButtonActive={isButtonActive}
                      permissions={permissions}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>

      {/* Delete Modal */}
      <DeleteMenu
        item={item}
        show={deleteModalShow}
        handleClose={handleCloseDeleteModal}
      />

      <MenuForm
        show={formShow}
        handleClose={handleCloseForm}
        parentId={item.id}
        parentName={item.name}
        parentPath={item.path}
        permissions={permissions}
      />

      <EditMenuForm
        show={editShow}
        handleClose={() => setEditShow(false)}
        item={item}
        permissions={permissions}
      />
    </>
  )
}

export default SortableItem
