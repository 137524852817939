import { TurnAroundTimeMinMedianMax } from "modules/PortPredictor/hooks/useFetchPortTurnAroundTime"
import { VesselActivity } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { CheckedMinMedianMax } from "modules/PortPredictor/portPredictorReducer"
import { Form, OverlayTrigger, Popover } from "react-bootstrap"

import { PortPredictorPortTypeSelectOption } from "./PortPredictorArrivalInputs"
import { VesselSegment } from "user/userService"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

interface PortPredictorMinMedianMaxButtonsProps {
  segment: VesselSegment
  portType: PortPredictorPortTypeSelectOption
  cargo: string
  portName: string
  portTurnAroundTimeMinMedianMax: TurnAroundTimeMinMedianMax
  portTurnAroundTime: number | undefined
  setPortTurnAroundTime: (time: number) => void
}

const renderTooltip = ({
  segment,
  cargo,
  portType,
  portName,
}: Pick<
  PortPredictorMinMedianMaxButtonsProps,
  "segment" | "cargo" | "portType" | "portName"
>) => {
  const activity =
    portType.activity === VesselActivity.Discharge ? "discharging" : "loading"
  return (
    <Popover id="popover-basic">
      <Popover.Body>{`Est. Turn around times based on last 10 ${segment} ships ${activity} ${cargo} at ${portName} `}</Popover.Body>
    </Popover>
  )
}

export function PortPredictorMinMedianMaxButtons({
  segment,
  portType,
  cargo,
  portName,
  portTurnAroundTimeMinMedianMax,
  portTurnAroundTime,
  setPortTurnAroundTime,
}: PortPredictorMinMedianMaxButtonsProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={renderTooltip({ segment, portType, cargo, portName })}
      >
        <div className="PortPredictor_MinMaxMedianInfoIcon_Wrapper ">
          <i className="PortPredictor_MinMaxMedianInfoIcon ti-info-alt" />
        </div>
      </OverlayTrigger>
      <div className="PortPredictor_MinMaxMedianButtonsWrapper mt-1">
        <div className="PortPredictor_MinMaxMedianButtons mt-1">
          {(
            Object.keys(portTurnAroundTimeMinMedianMax) as CheckedMinMedianMax[]
          ).map((label) => {
            const days = portTurnAroundTimeMinMedianMax[label].value
            return (
              <div
                key={label}
                className="mb-1 PortPredictor_CheckBoxSelector_ItemWrapper"
              >
                <Form.Check
                  className="p-0  PortPredictor_CheckBoxSelector_Item"
                  type="checkbox"
                  id={String(days)}
                  disabled={!getTabStateKeyValue('isEditMode')}
                >
                  <div className="PortPredictor_CheckBoxSelector_CheckInput">
                    <Form.Check.Input
                      className="p-0 m-0"
                      style={{ float: "revert" }}
                      type="checkbox"
                      checked={Number(portTurnAroundTime) === days}
                      onChange={(event) => {
                        setPortTurnAroundTime(Number(event.target.id))
                      }}
                      disabled={!getTabStateKeyValue('isEditMode')}
                    />
                    <Form.Check.Label
                      style={{ width: "unset" }}
                      className="m-0 ms-1"
                    >
                      {days}
                    </Form.Check.Label>
                  </div>
                  <div className="m-0 PortPredictor_CheckBoxSelector_Label">
                    {label}
                  </div>
                </Form.Check>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
