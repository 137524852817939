import axios from "axios"
import { Formik, FormikHelpers } from "formik"
import {
  Alert,
  Button, Form,
  Modal
} from "react-bootstrap"
import * as yup from "yup"

export const ModalWithInput = function (
  show: boolean,
  voyageName: string,
  modalHeading: string,
  labelName: string,
  saveHandler: (newVoyageName: string) => Promise<void>,
  closeHandler: () => void
) {
  const validationSchema = yup.object().shape({
    newVoyageName: yup.string().required("Voyage name is required."),
  })

  return (
    <Modal show={show} onHide={closeHandler} key={modalHeading + labelName}>
      <Modal.Header closeButton>
        <Modal.Title>{modalHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ newVoyageName: voyageName }}
          onSubmit={async (
            values: {
              newVoyageName: string
            },
            {
              setSubmitting, setStatus,
            }: FormikHelpers<{
              newVoyageName: string
            }>
          ) => {
            try {
              // Pass the updated voyageName name to the parent component
              await saveHandler(values.newVoyageName)
              closeHandler()
            } catch (err) {
              let errMsg = "Unexpected error"
              if (axios.isAxiosError(err)) {
                errMsg = err.response?.data
              }
              setStatus(`${errMsg}. Please try again.`)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, touched, isSubmitting, status,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Label
                htmlFor="modalInput"
                style={{ width: "100%", display: "block" }}
              >
                {labelName}
              </Form.Label>
              <Form.Control
                type="text"
                id="modalInput"
                name="newVoyageName"
                placeholder={voyageName}
                value={values.newVoyageName}
                autoFocus
                onChange={handleChange}
                isInvalid={touched.newVoyageName && !!errors.newVoyageName} />
              <Form.Control.Feedback type="invalid" style={{ marginLeft: "0px" }}>
                {errors.newVoyageName}
              </Form.Control.Feedback>
              {status && <Alert variant="danger">{status}</Alert>}
              <hr
                style={{ left: "-20px", position: "relative", width: "120%" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={closeHandler}
                  disabled={isSubmitting}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export const ModalDeleteConfirmation = (
  show: boolean,
  voyageName: string,
  deleteHandler: (id: number | undefined) => void,
  closeHandler: () => void
) => {
  return (
    <Modal show={show} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Delete voyage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the "{voyageName}" voyage?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Close
        </Button>
        <Button variant="danger" onClick={() => deleteHandler(undefined)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
