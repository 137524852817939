import React from "react"
import Select, {
  components,
  MultiValue,
  OptionProps,
  SingleValue,
} from "react-select"
import iconData from "../../shared/assets/ti-icons.json"

// Define the structure of our icon data
interface IconCategory {
  [category: string]: string[]
}

interface IconOption {
  value: string | null
  label: string
}

// Type assertion for the imported data
const typedIconData = iconData as IconCategory

// Create options for react-select
const options = [
  { value: null, label: "None" },
  ...Object.entries(typedIconData).flatMap(([category, icons]) => [
    {
      label: category,
      options: icons.map((icon) => ({
        value: icon.split("ti-")[1],
        label: icon.split("ti-")[1],
      })),
    },
  ]),
]

interface IconSelectProps {
  onChange: (selectedIcon: string | null) => void
  initValue?: IconOption
}

// Custom Option component to render the icon and label
const IconOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <i className={`ti-` + props.data.value}></i> {props.data.label}
    </components.Option>
  )
}

// Custom SingleValue component to render the selected icon and label
const IconSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <i className={`ti-` + props.data.value}></i> {props.data.label}
    </components.SingleValue>
  )
}

const IconSelect: React.FC<IconSelectProps> = ({ onChange, initValue }) => {
  return (
    <Select<IconOption>
      value={initValue}
      options={options}
      onChange={(option) => onChange(option?.value || null)}
      placeholder="Select / Search an icon"
      isClearable
      isSearchable
      className="w-100"
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
    />
  )
}

export default IconSelect
