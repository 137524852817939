import ReactEcharts, { EChartsOption } from "echarts-for-react"
import { Modal } from "react-bootstrap"
import { formatCargoLabel } from "../portPredictorFormatCargoLabelHelper"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

interface PortPredictorPortTurnAroundTimeModalProps {
  closePortTurnAroundTimeModal: () => void
  isPortTurnAroundTimeModalOpen: boolean
  portTurnAroundTimeChartOptions: EChartsOption
  chosenPortLabel: string | undefined
  chosenCargo: string
}

export function PortPredictorPortTurnAroundTimeModal({
  isPortTurnAroundTimeModalOpen,
  closePortTurnAroundTimeModal,
  portTurnAroundTimeChartOptions,
  chosenPortLabel,
  chosenCargo,
}: PortPredictorPortTurnAroundTimeModalProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  return (
    <Modal size="lg" show={isPortTurnAroundTimeModalOpen}>
      <div className="modal-header d-flex justify-content-center">
        <h5 className="modal-title mt-0" id="OverviewModalLabel">
          Port Turn Around Time at {chosenPortLabel}, for{" "}
          {formatCargoLabel(chosenCargo)}
        </h5>
        <button
          onClick={() => {
            closePortTurnAroundTimeModal()
          }}
          disabled={!getTabStateKeyValue('isEditMode')}
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="pt-2 pb-2 d-flex justify-content-center">
        <ReactEcharts
          style={{
            height: "400px",
            width: "650px",
          }}
          option={portTurnAroundTimeChartOptions}
        />
      </div>
    </Modal>
  )
}
