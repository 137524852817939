import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faAngleDown,
  faAsterisk,
  faCancel,
  faCopy,
  faEdit,
  faEraser,
  faFileLines,
  faPlus,
  faSave,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Itineraries,
  Itinerary,
} from "modules/PortPredictor/hooks/useItineraryAPI"
import React, { Fragment, HTMLProps, Ref, forwardRef, useState } from "react"
import {
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap"
import { FormatDateDisplay, TrimName } from "./Utilities"

interface TabMenuProps extends HTMLProps<HTMLDivElement> {
  "aria-labelledby": string
  handlenew: () => void
}

export const CreateItineraryMenuList = (
  itineraries: Itinerary[] | undefined,
  addItineraryHandler: (itinerary: Itinerary) => void,
  deleteHandler: (id: number, name: string) => void
): JSX.Element[] | undefined => {
  return itineraries
    ?.filter((item) => !item.isNew && item.id! > 0)
    .sort((a, b) => b.id! - a.id!)
    .map((item) => (
      <NavDropdown.Item
        key={item.id}
        onClick={(event: { preventDefault: () => void }) => {
          event.preventDefault()
          addItineraryHandler(item)
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ visibility: "hidden", display: "none" }}>{`${item.name
            } ${FormatDateDisplay(
              item.createdAt || new Date().toString()
            )}`}</span>
          <div>
            <div style={{ marginRight: '15px' }}>{item.name}</div>
            <div
              style={{
                color: "rgb(113, 110, 110, .75)",
                fontSize: "smaller",
              }}
            >
              {FormatDateDisplay(item.createdAt || new Date().toString())}
            </div>
          </div>

          {/* <FontAwesomeIcon
              icon={faTrash}
              className="fa-thin"
              style={{
                color: "rgb(255,0,0,.60)",
              }}
              onClick={(event) => {
                event.preventDefault(); 
                event.stopPropagation();
                deleteHandler(item.id!, item.name)
              }}
              title="Delete"
            /> */}
          <div
            style={{
              position: "relative",
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="fa-thin"
              style={{
                color: "rgb(255,0,0,.60)",
              }}
            />
            <div
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                deleteHandler(item.id!, item.name)
              }}
              title="Delete"
              style={{
                position: "absolute",
                top: "-10px", // Adjust the position as needed
                left: "-10px", // Adjust the position as needed
                right: "-10px", // Adjust the position as needed
                bottom: "-10px", // Adjust the position as needed
                border: "1px solid red", // Light gray border
                borderRadius: "5px", // Rounded corners
                boxShadow: "0 0 5px rgba(255, 0, 0, 0.1)", // Emboss effect
                opacity: 0, // Initially hidden
                transition: "opacity 0.3s", // Smooth transition
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.opacity = "1" // Show on mouse enter
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.opacity = "0" // Hide on mouse leave
              }}
            ></div>
          </div>
        </div>
      </NavDropdown.Item>
    ))
}

const AddTabToggle = forwardRef<HTMLAnchorElement, any>(({ onClick }, ref) => (
  <a
    style={{ margin: "10px" }}
    href=""
    ref={ref as Ref<HTMLAnchorElement>}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <FontAwesomeIcon icon={faPlus} />
    {/* &nbsp;&nbsp;
    <FontAwesomeIcon icon={faAngleDown} /> */}
  </a>
))

export const ConstructTabMenuItem = (
  iconName: IconProp,
  label: string,
  disable: boolean,
  color: string | undefined
): JSX.Element => {
  const rgbOpacity = disable ? "0.45" : "1"
  const itemColor = `rgb(91, 98, 107, ${rgbOpacity})`
  return (
    <>
      <FontAwesomeIcon
        icon={iconName}
        className="fa-thin"
        style={{
          color: color && !disable ? color : itemColor,
        }}
      />
      <span
        style={{
          color: itemColor,
        }}
      >
        &nbsp;&nbsp;&nbsp;{label}
      </span>
    </>
  )
}

const AddTabMenu = forwardRef<HTMLDivElement, TabMenuProps>(
  (
    { children, style, className, "aria-labelledby": labeledBy, handlenew },
    ref
  ) => {
    const [value, setValue] = useState("")
    const lowerCaseValue = value.toLowerCase()
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <NavDropdown.Item
          eventKey="new"
          onClick={() => {
            handlenew()
          }}
        >
          {ConstructTabMenuItem(faFileLines, "New", false, undefined)}
        </NavDropdown.Item>
        {React.Children.count(children) > 0 && (
          <>
            <NavDropdown.Divider />
            <Form.Control
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Type to filter..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <ul
              className="list-unstyled"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {React.Children.toArray(children).filter((child) => {
                return (
                  !value ||
                  (
                    child as React.ReactElement
                  ).props.children.props.children.some(
                    (item: React.ReactNode) => {
                      const searcheableChild = item as React.ReactElement
                      return (
                        searcheableChild.type === "span" &&
                        searcheableChild.props.children
                          .toLowerCase()
                          .includes(lowerCaseValue)
                      )
                    }
                  )
                )
              })}
            </ul>
          </>
        )}
      </div>
    )
  }
)

export const ConstructAddTab = (
  itineraries: Itineraries,
  handleNew: () => void,
  addItineraryHandler: (itinerary: Itinerary) => void,
  deleteHandler: (id: number, name: string) => void
): JSX.Element => {
  return (
    <Dropdown key="add" style={{ display: "flex", margin: 0 }}>
      <Dropdown.Toggle as={AddTabToggle} id="dropdown-custom-components">
        +
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ margin: 0 }}
        as={AddTabMenu}
        handlenew={handleNew}
      >
        {CreateItineraryMenuList(
          itineraries,
          addItineraryHandler,
          deleteHandler
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const ConstructTabLabel = (
  label: string,
  createdDate: string,
  isDirty: boolean,
  id: number,
  closeTabHandler: ((id: number) => void) | undefined
): JSX.Element => {
  const isLoadingTab = id === -1
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 1000, hide: 400 }}
      overlay={
        <Tooltip id="tooltip-top">
          <>
            <div>{label}</div>
            {createdDate ? <div>{FormatDateDisplay(createdDate)}</div> : ""}
          </>
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div
          ref={ref}
          {...triggerHandler}
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            // border: "1px solid blue"
          }}
        >
          {isLoadingTab && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;
            </>
          )}
          {TrimName(label)}
          &nbsp;&nbsp;

          {!isLoadingTab && (
            <>
              {isDirty && (
                <div>[Edit]</div>
              )}
              {closeTabHandler && (
                <>
                  <div>&nbsp;&nbsp;&nbsp;</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} size="xs" style={{}} />
                    <div
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        closeTabHandler(id)
                      }}
                      title="Close"
                      style={{
                        position: "absolute",
                        top: "-10px",
                        left: "-10px",
                        right: "-10px",
                        bottom: "-10px",
                        border: "1px solid lightgray",
                        borderRadius: "3px",
                        boxShadow: "0 0 5px rgba(211, 211, 211, 0.1)", // Emboss effect
                        opacity: 0, // Initially hidden
                        transition: "opacity 0.3s", // Smooth transition
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.opacity = "1" // Show on mouse enter
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.opacity = "0" // Hide on mouse leave
                      }}
                    ></div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </OverlayTrigger>
  )
}

export const ConstructItineraryTab = (
  itineraries: Itineraries,
  selectedId: number,
  setEditHandler: () => void,
  cancelEditHandler: () => void,
  saveHandler: (updatedName: string | undefined) => void,
  renameHandler: () => void,
  duplicateHandler: () => void,
  deleteHandler: () => void,
  closeTabHandler: (id: number) => void
): JSX.Element[] => {
  let visibleTab: any[] = []
  const openedItineraries =
    itineraries &&
    itineraries
      .filter((itinerary) => itinerary.isOpened)
      .sort((a, b) => a.openSeqId! - b.openSeqId!)
  const hasMoreThanOneOpened = openedItineraries
    ? openedItineraries?.length > 1
    : false
  openedItineraries?.forEach((itinerary: Itinerary, index: number) => {
    const hasNoUuid = !itinerary.uuid || itinerary.uuid === ""
    if (itinerary.id === selectedId) {
      visibleTab.push(
        <NavDropdown
          active
          title={ConstructTabLabel(
            itinerary.name,
            itinerary.createdAt || "",
            !!itinerary.isEditMode,
            itinerary.id,
            hasMoreThanOneOpened ? closeTabHandler : undefined
          )}
          id="nav-dropdown"
          key={itinerary.id}
          style={{ margin: 0 }}
        >
          {!hasNoUuid
            ? (<NavDropdown.Item
              eventKey={!itinerary.isEditMode ? "setEdit" : "setCancel"}
              title={!itinerary.isEditMode ? "Edit" : "Cancel"}
              key="setEdit"
              onClick={() => !itinerary.isEditMode ? setEditHandler() : cancelEditHandler()}
            >
              {ConstructTabMenuItem(
                !itinerary.isEditMode ? faEdit : faCancel,
                !itinerary.isEditMode ? "Edit" : "Cancel",
                false,
                undefined
              )}
            </NavDropdown.Item>)
            : null}

          <NavDropdown.Item
            eventKey="save"
            title="Save"
            key="save"
            onClick={() => saveHandler(undefined)}
            disabled={!itinerary.isEditMode}
          >
            {ConstructTabMenuItem(
              faSave,
              "Save",
              !itinerary.isEditMode,
              undefined
            )}
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="rename"
            title="Rename"
            key="rename"
            onClick={renameHandler}
            disabled={hasNoUuid}
          >
            {ConstructTabMenuItem(faEdit, "Rename", hasNoUuid, undefined)}
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="duplicate"
            title="Duplicate"
            key="duplicate"
            onClick={duplicateHandler}
            disabled={!!itinerary.isEditMode || hasNoUuid}
          >
            {ConstructTabMenuItem(
              faCopy,
              "Duplicate",
              !!itinerary.isEditMode || hasNoUuid,
              undefined
            )}
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="delete"
            title="Delete"
            key="delete"
            onClick={() => hasNoUuid ? closeTabHandler(itinerary.id!) : deleteHandler()}
            disabled={!hasMoreThanOneOpened}
          >
            {ConstructTabMenuItem(
              faTrash,
              "Delete",
              !hasMoreThanOneOpened,
              "rgb(255,0,0,.6)"
            )}
          </NavDropdown.Item>
          {
            <Fragment key="close">
              <NavDropdown.Divider />
              <NavDropdown.Item
                eventKey="close"
                title={(hasNoUuid && !hasMoreThanOneOpened) ? "Clear" : "Close"}
                key="close"
                onClick={() => closeTabHandler(itinerary.id!)}
              >
                {ConstructTabMenuItem((hasNoUuid && !hasMoreThanOneOpened) ? faEraser : faXmark, (hasNoUuid && !hasMoreThanOneOpened) ? "Clear" : "Close", false, undefined)}
              </NavDropdown.Item>
            </Fragment>
          }
        </NavDropdown >
      )
    } else {
      visibleTab.push(
        <Nav.Item key={itinerary.id}>
          <Nav.Link title={itinerary.name} eventKey={itinerary.id}>
            {ConstructTabLabel(
              itinerary.name,
              itinerary.createdAt || "",
              !!itinerary.isEditMode,
              itinerary.id!,
              hasMoreThanOneOpened ? closeTabHandler : undefined
            )}
          </Nav.Link>
        </Nav.Item>
      )
    }
  })
  return visibleTab
}
