import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import { useGetPermissions } from "../../hooks/useGetPermissions"
import { useGetSidebarItems } from "../../hooks/useGetSidebarItems"
import { SidebarItem } from "../../shared/types"
import { Loader } from "lucide-react"
import { PermissionAll } from "../../shared/types"
import "./assets/spinner.css"

// Function to collect used permissions recursively from sidebarItems
const collectUsedPermissions = (sidebarItems: SidebarItem[]) => {
  const usedPermissions = new Set<string>()

  const collect = (items: SidebarItem[]) => {
    items.forEach((item) => {
      if (item.permission) {
        usedPermissions.add(item.permission)
      }
      if (item.subMenus) {
        collect(item.subMenus) // Recursively collect permissions from submenus
      }
    })
  }

  collect(sidebarItems)
  return usedPermissions
}

function DanglingPermissions() {
  const { getAllPermission, permissionList } = useGetPermissions()
  const { sidebarItems } = useGetSidebarItems()
  const [danglingPermissions, setDanglingPermissions] = useState<
    PermissionAll[]
  >([])
  const [showModal, setShowModal] = useState(false) // To control the modal visibility
  const [currentPage, setCurrentPage] = useState(1) // For pagination
  const [permissionsPerPage] = useState(10) // Set the number of permissions per page

  useEffect(() => {
    getAllPermission()
  }, [])

  useEffect(() => {
    if (permissionList && sidebarItems) {
      const usedPermissions = collectUsedPermissions(sidebarItems)

      // Map and filter dangling permissions
      const dangling = permissionList
        .map((p) => {
          // Handle both PermissionAll and string permissions
          return typeof p === "string"
            ? { value: p, description: p }
            : { value: p.value, description: p.description }
        })
        .filter((permission) => !usedPermissions.has(permission.value))

      setDanglingPermissions(dangling) // Update dangling permissions state
    }
  }, [permissionList, sidebarItems])

  // Pagination logic
  const indexOfLastPermission = currentPage * permissionsPerPage
  const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage
  const currentPermissions = danglingPermissions.slice(
    indexOfFirstPermission,
    indexOfLastPermission
  )
  const totalPages = Math.ceil(danglingPermissions.length / permissionsPerPage)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  // Tooltip rendering function
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to see all {danglingPermissions.length} dangling permissions
    </Tooltip>
  )

  return (
    <>
      {danglingPermissions.length > 0 ? (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button
              variant="warning"
              className="text-black"
              onClick={() => setShowModal(true)}
            >
              Permissions{" "}
              <Badge bg="primary">{danglingPermissions.length}</Badge>
            </Button>
          </OverlayTrigger>
        </>
      ) : (
        <Loader size={24} strokeWidth={2} className="spinner" />
      )}

      {/* Modal for displaying dangling permissions */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Dangling Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {danglingPermissions.length > 0 ? (
            <>
              <p>
                The table below contains the permissions that are not being used
                by the sidebar menus.
              </p>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPermissions.map(
                    (permission: PermissionAll, index: number) => (
                      <tr key={index}>
                        <td>{permission.value}</td>
                        <td>{permission.description}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
              {/* Pagination */}
              <Pagination>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
              <i>
                Note: Not all dangling permissions are safe to remove from
                Auth0, as some serve as secondary security measures. Before
                deleting any permission, please ensure to coordinate with
                another admin.
              </i>
            </>
          ) : (
            <p>No dangling permissions found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DanglingPermissions
