import React, { useEffect, useState } from "react"
import { useGetPermissions } from "../../hooks/useGetPermissions"
import Select, { components, SingleValue, ActionMeta } from "react-select"
import { Spinner } from "react-bootstrap"
import { SelectOptions } from "../../shared/types"
import { PermissionAll, PermissionSelectProps } from "../../shared/types"
import { CREATE_PERMISSION } from "../../shared/libs"

const PermissionSelect: React.FC<PermissionSelectProps> = ({
  onChange,
  initValue,
}) => {
  const { getAllPermission, permissionList, isLoading } = useGetPermissions()
  const [items, setItems] = useState<SelectOptions[]>([])
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false)

  useEffect(() => {
    getAllPermission()
  }, [])

  useEffect(() => {
    if (permissionList && permissionList.length > 0) {
      // Start with an initial value
      const initialPermissions: SelectOptions[] = [
        {
          value: CREATE_PERMISSION, 
          label: CREATE_PERMISSION,
        },
      ]

      // Map over the permissionList and add it to the initial array
      const tempPermissions: SelectOptions[] = [
        ...initialPermissions,
        ...permissionList.map((item) => {
          const permission = item as PermissionAll
          return {
            value: permission.value,
            label: permission.value,
          }
        }),
      ]

      setItems(tempPermissions)
      setIsInitialLoadComplete(true)
    }
  }, [permissionList])

  const customComponents = {
    ...components,
    NoOptionsMessage: (props: any) => (
      <components.NoOptionsMessage {...props}>
        {isLoading && !isInitialLoadComplete ? (
          <Spinner animation="border" role="status" />
        ) : props.children ? (
          "No matching options"
        ) : (
          "No options available"
        )}
      </components.NoOptionsMessage>
    ),
  }

  const handleChange = (
    newValue: SingleValue<SelectOptions>,
    actionMeta: ActionMeta<SelectOptions>
  ) => {
    onChange(newValue ? newValue.value : "")
  }

  return (
    <Select<SelectOptions>
      value={initValue}
      options={items}
      onChange={handleChange}
      isClearable
      isSearchable
      className="w-100"
      components={customComponents}
      isLoading={isLoading && !isInitialLoadComplete}
    />
  )
}

export default PermissionSelect
