import { PowerBiWrapper } from "modules/PowerBi/PowerBiWrapper"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { AppRouteSingle } from "user/userService"

export function useGetPowerBiRoutes() {
  const appRoutesFlattened = useSelector(
    (state: RootState) => state.user.appRoutesFlattened
  )

  const [routes, setRoutes] = useState<AppRouteSingle[]>([])

  useEffect(() => {
    const tempRoutes = [] as AppRouteSingle[]
    if (!appRoutesFlattened) {
      return
    }

    for (let path of Object.keys(appRoutesFlattened)) {
      const item = appRoutesFlattened[path]
      if (item.groupId && item.isPage) {
        tempRoutes.push(item)
      }
    }

    setRoutes(tempRoutes)
  }, [appRoutesFlattened])

  return [
    ...routes.map((menuItem) => {
      return {
        path: menuItem.path,
        permissionForPage: menuItem.permission,
        component: () => {
          return (
            <PowerBiWrapper
              reportId={menuItem.reportId as string}
              groupId={menuItem.groupId as string}
              permission={menuItem.permission}
              pageName={menuItem.pageName as string}
              sectionTitle={""}
              title={menuItem.name}
              breadcrumbItem={menuItem.name}
              isPreview = {false}
            />
          )
        },
      }
    }),
  ]
}
