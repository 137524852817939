import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

export interface VesselPositions {
  pos_date: string
  updated_at: string
  destination: string
  lon: number
  lat: number
  sog: number
  draft_ratio: number
  draft_laden: boolean
  model_laden: boolean
  master_destination: string
  master_eta: string
}

interface UseFetchVesselPositionsArgs {
  imo?: string | number
  segment?: string
  months: number
}

export function useFetchVesselPositions4({ imo, segment, months }: UseFetchVesselPositionsArgs) {
  const request = useAxiosRequest()

  const [vesselPositions, setVesselPositions] = useState<VesselPositions[] | undefined>()
  const [vesselPositionsLoading, setVesselPositionsLoading] = useState(false)
  const [vesselPositionsError, setVesselPositionsError] = useState<string | undefined>()
  let mounted = useRef(true)

  useEffect(() => {
    mounted.current = true
    const controller = new AbortController()

    getVesselPositions3({ imo, segment, months, controller })

    return () => {
      mounted.current = false
      controller.abort()
    }
  }, [imo, months, segment])

  async function getVesselPositions3({
    imo,
    segment,
    months,
    controller,
  }: UseFetchVesselPositionsArgs & {
    controller?: AbortController
  }) {
    if (mounted.current) {
      setVesselPositionsLoading(true)
    }
    try {
      // debugger
      const responseBody = await request<any, VesselPositions[]>({
        signal: controller?.signal,
        url: `${BASE_URL}/prevetting/vessel-positions`,
        params: { segment, months, imo },
      })

      if (mounted.current && responseBody) {
        setVesselPositions(responseBody.data)
      }
    } catch (err: unknown) {
      if (mounted.current) {
        if (axios.isAxiosError(err)) {
          if (imo && segment) {
            toast.warn(`Unable to load vessel positions (AIS data) data for vessel with imo ${imo}`, {
              position: "bottom-right",
              autoClose: 8000,
            })
          }
        }
      }
    } finally {
      if (mounted.current) {
        setVesselPositionsLoading(false)
      }
    }
  }

  return { vesselPositions, vesselPositionsLoading, vesselPositionsError, getVesselPositions3 }
}
