import React from "react"
import { Button, Modal } from "react-bootstrap"
import { EditRearrangeProps } from "../../shared/types"
import { useEditMenu } from "../../hooks/useEditMenu"
import { useQueryClient } from "@tanstack/react-query"

const EditRearrange: React.FC<EditRearrangeProps> = ({
  show,
  handleClose,
  items,
}) => {
  const { BulkUpdateMenu, isEditing } = useEditMenu({})
  const queryClient = useQueryClient()
  const handleEditMenu = async () => {
    items.forEach((item, index)=>{
      item.position = index+1
    })
    await BulkUpdateMenu(items)
    handleClose()
    queryClient.invalidateQueries(['sidebarItems'])
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to save changes?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-dark border border-dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleEditMenu} disabled={isEditing}>
          {isEditing ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Updating
            </>
          ) : (
            <>Update</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditRearrange
