import { useAxiosRequest } from "api/axiosRequest"
import { CreatePermissionProps } from "../shared/types"
import { useState } from "react"
import { toast } from "react-toastify"
import axios from "axios"

export const useCreatePermission = () => {
  const request = useAxiosRequest()
  const [isCreatingPermission, setIsCreatingPermission] =
    useState<boolean>(false)

  async function createPermission(params: CreatePermissionProps) {
    try {
      setIsCreatingPermission(true)
      const response = await request<any, unknown>({
        url: "api/v1/permissions/single",
        method: "POST",
        data: params,
      })
      if (response) {
        toast.success("Permission created successfully", {
          position: "bottom-right",
          autoClose: 8000,
        })
        
      }
    } catch (err: unknown) {
      let message = "Error creating permission"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        const statusCode = err?.response?.status
        if (statusCode === 409) {
          message = "Maintenance overlaps with existing event"
        } else {
          message = axiosErrorMessage
            ? message + ": " + axiosErrorMessage
            : message + "."
        }
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      toast.error(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setIsCreatingPermission(false)
    }
  }

  return { createPermission, isCreatingPermission }
}
