import BigNumber from "bignumber.js"
import { Map as LeafletMap } from "leaflet"
import { Button } from "react-bootstrap"
import { VesselSegment } from "user/userService"
import {
  PortPredictorPortsData,
  PortPredictorVesselSpeed,
  PortPredictorVesselStateDataWithMajorCargo,
} from "../../hooks/usePortPredictorAPI"
import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorJourneyState,
  PortType,
} from "../../portPredictorReducer"
import { PortPredictorPortTypeSelectOption } from "../PortPredictorArrival/PortPredictorArrivalInputs"
import { getVesselSpeedDataWithAverages } from "../PortPredictorDeparture/helpers"
import { MarkerLatLon } from "../PortPredictorMain"
import { PortPredictorMarker } from "../PortPredictorMarker/PortPredictorMarker"
import { createMarkerBase } from "./createMarkerBase"
import {
  recalculateTimeline,
  recalculateTimelineAfterDelete,
} from "./recalculateTimeline"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

export const defaultTimeRangeForHistoricalVesselSpeed = 3
interface PortPredictorTimelineProps {
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
  journeyMarkerState: PortPredictorJourneyState
  segment: VesselSegment
  dispatchEditJourney: (markers: PortPredictorJourneyMarker[]) => void
  dispatchAddJourneyStartMarkers: (
    markers: PortPredictorJourneyMarker[]
  ) => void
  dispatchExtendJourneyMarkers: (markers: PortPredictorJourneyMarker[]) => void
  showSpeedGraphModal: () => void
  journeyStartMarkersArr: PortPredictorJourneyMarker[] | undefined
  map: LeafletMap | null
  markers: MarkerLatLon[]
}

function isAddMarkerDisabled(journeyMarkerState: PortPredictorJourneyState) {
  const lastMarker = journeyMarkerState[journeyMarkerState.length - 1]
  if (!lastMarker) {
    return false
  }

  if (lastMarker.markerType === MarkerType.Arrival) {
    const { arrivalDate, cargo, port, turnAroundTime, portType } = lastMarker

    if (
      lastMarker.markerType === MarkerType.Arrival &&
      lastMarker.portType === PortType.Other
    ) {
      return !arrivalDate || !port || turnAroundTime === undefined
    }

    // Disabled if any of these fields are undefined
    return !arrivalDate || !port || turnAroundTime === undefined
  }

  if (lastMarker.markerType === MarkerType.Departure) {
    const { date, vesselSpeedSelected } = lastMarker
    // DIsable if date or vesselSpeed not present
    return !date || !vesselSpeedSelected
  }
  return false
}

export interface HandleArrivalInputsArgs {
  turnAroundTime: BigNumber
  chosenCargo: string
  chosenPort: PortPredictorPortsData
  chosenPortType: PortPredictorPortTypeSelectOption
  showSpeedGraphModal: () => void
}

export function PortPredictorTimeline({
  showSpeedGraphModal,
  chosenVesselSpeedData,
  chosenVesselStateData,
  dispatchEditJourney,
  dispatchExtendJourneyMarkers,
  dispatchAddJourneyStartMarkers,
  journeyMarkerState,
  journeyStartMarkersArr,
  segment,
  map,
  markers,
}: PortPredictorTimelineProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  const onDeleteMarker = (deletedMarker: PortPredictorJourneyMarker) => {
    const modifiedRestOfJourneyAfterDelete = recalculateTimelineAfterDelete({
      journeyMarkerState,
      deletedMarker,
    })

    if (!modifiedRestOfJourneyAfterDelete) {
      return
    }

    dispatchEditJourney(modifiedRestOfJourneyAfterDelete)
  }

  const speedDataWithAvg = getVesselSpeedDataWithAverages(chosenVesselSpeedData)

  const onUpdateMarker = (updatedMarker: PortPredictorJourneyMarker) => {
    const modifiedRestOfJourney = recalculateTimeline({
      journeyMarkerState,
      startFromMarker: updatedMarker,
      speedDataWithAvg,
    })

    if (!modifiedRestOfJourney) {
      return
    }

    dispatchEditJourney(modifiedRestOfJourney)
  }

  return (
    <div className="PortPredictor_Timeline mt-4">
      {chosenVesselStateData ? (
        <div>
          <div className="PortPredictor_Timeline_Inner">
            {journeyMarkerState.map((currMarker, index) => {
              return (
                <div
                  key={`${currMarker.id}_${currMarker.markerType}_${currMarker.port?.port}_${currMarker.vesselState}`}
                  className="PortPredictor_Marker"
                >
                  {/* {JSON.stringify(currMarker)} */}
                  {/* <div>Dax</div><br/> */}
                  <PortPredictorMarker
                    index={index}
                    currMarker={currMarker}
                    chosenVesselSpeedData={chosenVesselSpeedData}
                    chosenVesselStateData={chosenVesselStateData}
                    journeyMarkerState={journeyMarkerState}
                    isStartOfJourney={index === 0}
                    onUpdateMarker={(marker) => onUpdateMarker(marker)}
                    onDeleteMarker={(marker) => onDeleteMarker(marker)}
                    segment={segment}
                    map={map}
                    markers={markers as MarkerLatLon[]}
                    showSpeedGraphModal={showSpeedGraphModal}
                    changeOpenSeaToPort={() => {
                      if (currMarker.markerType !== MarkerType.Departure) {
                        return
                      }
                      if (
                        journeyMarkerState[0].markerType ===
                        MarkerType.Departure
                      ) {
                        const add = [
                          ...journeyMarkerState.slice(
                            1,
                            journeyMarkerState.length
                          ),
                        ].map((marker, idx) => {
                          return { ...marker, id: idx }
                        })

                        dispatchAddJourneyStartMarkers(add)
                        return
                      }
                      // If starting marker was a port and user change to Open Sea and now back to port
                      // preserve the info from the starting marker, do not create new arrival marker
                    }}
                    revertPortToOpenSea={() => {
                      if (!journeyStartMarkersArr) {
                        return
                      }
                      dispatchAddJourneyStartMarkers(journeyStartMarkersArr)
                    }}
                  />
                </div>
              )
            })}
            {journeyMarkerState.length > 0 && (
              <Button
                className="PortPredictor_AddMarkerButton"
                disabled={
                  isAddMarkerDisabled(journeyMarkerState) ||
                  !getTabStateKeyValue("isEditMode")
                }
                onClick={() => {
                  const markerBase = createMarkerBase({
                    journeyMarkerState,
                    chosenVesselSpeedData,
                  })
                  if (!markerBase) {
                    return
                  }
                  dispatchExtendJourneyMarkers(
                    markerBase as PortPredictorJourneyMarker[]
                  )
                }}
              >
                <i className="ti-plus me-1" />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div
          role="alert"
          className="w-100 fade bg-ifp mb-0 alert alert-info show"
        >
          Please select a vessel
        </div>
      )}
    </div>
  )
}
