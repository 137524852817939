import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useDeleteMenu } from "../../hooks/useDeleteMenu"
import { DeleteMenuProps } from "../../shared/types"

const DeleteMenu: React.FC<DeleteMenuProps> = ({ show, handleClose, item }) => {
  const { deleteMenu, isDeleting } = useDeleteMenu({})
  const handleDeleteMenu = async () => {
    await deleteMenu(item.id)
    handleClose()
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete:{" "}
          <span className="font-weight-bold">{item.name}</span>
        </p>
        {item.subMenus ? (
          <>
            <br />
            <p>This will also delete the following items:</p>
            <ul>
              {item.subMenus.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-dark border border-dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDeleteMenu}
          disabled={isDeleting}
        >
          {isDeleting ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Deleting
            </>
          ) : (
            <>Delete</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteMenu
