import { X } from "lucide-react"
import React, { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap"
import IconSelect from "../Selects/IconSelect"
import Select, { SingleValue } from "react-select"
import PermissionSelect from "../Selects/PermissionSelect"
import { useEditMenu } from "../../hooks/useEditMenu"
import { useCreatePermission } from "../../hooks/useCreatePermission"
import { CREATE_PERMISSION } from "../../shared/libs"
import PowerbiPreview from "./PowerbiPreview"
import { toast } from "react-toastify"
import {
  EditMenuFormProps,
  SelectProps,
  EditMenuParams,
} from "../../shared/types"
import {
  PERMISSION_UPDATE_MENU_PBI,
  selectBoolOptions,
  selectBoolOptionsPbi,
} from "../../shared/libs"
import "./assets/eye.css"

const EditMenuForm: React.FC<EditMenuFormProps> = ({
  show,
  handleClose,
  item,
  permissions,
}) => {
  const [path, setPath] = useState<string>(item.path)
  const [name, setName] = useState<string>(item.name)
  const [parentId, setParentId] = useState<string | null>(item.parentId)
  const [position, setPosition] = useState<number>(item.position)
  const [newPermission, setNewPermission] = useState<string | null>(null)
  const [icon, setSelectedIcon] = useState<string | null>(item.icon)
  const [permission, setSelectedPermission] = useState<string>(item.permission)
  const [isPbi, setIsPbi] = useState<boolean>(
    item.reportId === null || item.groupId === null || item.pageName === null
      ? false
      : true
  )
  const [externalLink, setExternalLink] = useState<string | null>(
    item.externalLink
  )
  const [reportId, setReportId] = useState<string | null>(item.reportId)
  const [groupId, setGroupId] = useState<string | null>(item.groupId)
  const [pageName, setPageName] = useState<string | null>(item.pageName)
  const [isPageValue, setIsPageValue] = useState<boolean>(item.isPage)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [pbiAdmin, setPbiAdmin] = useState<boolean>(false)
  const { editMenu, isEditing } = useEditMenu({})
  const { createPermission, isCreatingPermission } = useCreatePermission()

  const handleIconChange = (icon: string | null) => {
    setSelectedIcon(icon)
  }
  const handlePermissionChange = (permission: string) => {
    setSelectedPermission(permission)
  }

  const handleChangeSelectPBI = (selectedOption: SingleValue<SelectProps>) => {
    if (selectedOption) {
      setIsPbi(selectedOption.value)
      if (selectedOption.value === true) {
        setIsPageValue(true)
      }
    }
  }

  /* check if admin is pbionly */
  useEffect(() => {
    if (permission.includes(PERMISSION_UPDATE_MENU_PBI)) {
      setPbiAdmin(true)
      setIsPageValue(true)
      setPbiAdmin(true)
    }
  }, [permissions])

  const handleChangeSelectIsPage = (
    selectedOption: SingleValue<SelectProps>
  ) => {
    if (selectedOption) {
      setIsPageValue(selectedOption.value)
      setReportId(null)
      setGroupId(null)
      setPageName(null)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const menuItem: EditMenuParams = {
      id: item.id,
      path,
      name,
      icon: icon,
      isPage: isPageValue,
      permission: permission,
      externalLink,
      reportId,
      groupId,
      pageName,
      parentId,
      position,
    }
    try {
      if (permission === CREATE_PERMISSION && newPermission !== null) {
        await createPermission({ permission: newPermission })
        menuItem.permission = newPermission
        await editMenu(menuItem)
      } else {
        await editMenu(menuItem)
      }
    } catch (error: unknown) {
      toast.error("Failed to edit menu", {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setPath("")
      setName("")
      setSelectedIcon(null)
      setIsPageValue(false)
      setIsPbi(false)
      setExternalLink(null)
      setReportId(null)
      setGroupId(null)
      setPageName(null)
      setParentId(null)
      setPosition(0)
      handleClose()
    }
  }

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Header */}
          <div className="d-flex flex-row align-items-center justify-content-between mt-3 px-2 mb-3">
            <h5>Edit Menu - {item.name}</h5>
            <button
              className="border-none"
              onClick={handleClose}
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <X className="text-muted" />
            </button>
          </div>

          {/* Forms */}
          <Form onSubmit={handleSubmit}>
            {/* Path */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Path</Form.Label>
              <Form.Control
                value={path}
                type="text"
                placeholder={item.path}
                className="w-100"
                required
                onChange={(e) => setPath(e.target.value)}
              />
            </Form.Group>

            {/* Name */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                type="text"
                placeholder="Enter name here..."
                className="w-100"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            {/* Icon */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Icon</Form.Label>
              <IconSelect
                onChange={handleIconChange}
                initValue={{ value: icon, label: icon || "None" }}
              />
            </Form.Group>

            {/* Permissions */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Permissions</Form.Label>
              <PermissionSelect
                onChange={handlePermissionChange}
                initValue={{ value: permission, label: permission }}
              />
            </Form.Group>

            {/* Create new Permission */}
            {permission === CREATE_PERMISSION ? (
              <>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">New Permission</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter permission name here..."
                    className="w-100"
                    required
                    onChange={(e) => setNewPermission(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <div className="d-flex flex-row justify-content-between w-100">
              {/* ispage */}
              <Form.Group className="mb-3 px-1 w-100">
                <Form.Label>isPage</Form.Label>
                <Select
                  value={
                    isPageValue === true
                      ? { value: true, label: "Yes" }
                      : { value: false, label: "No" }
                  }
                  placeholder="Yes / No"
                  onChange={handleChangeSelectIsPage}
                  options={pbiAdmin ? selectBoolOptionsPbi : selectBoolOptions}
                />
              </Form.Group>

              {/* isPbi */}
              <Form.Group className="mb-3 px-1 w-100">
                <Form.Label>isPbi</Form.Label>
                <Select
                  value={
                    isPbi === true
                      ? { value: true, label: "Yes" }
                      : { value: false, label: "No" }
                  }
                  placeholder="Yes / No"
                  onChange={handleChangeSelectPBI}
                  options={pbiAdmin ? selectBoolOptionsPbi : selectBoolOptions}
                />
              </Form.Group>
            </div>

            {/* ExternalLink */}
            <Form.Group className="mb-3 px-1">
              <Form.Label className="w-100">External Link</Form.Label>
              <Form.Control
                value={externalLink === null ? "" : externalLink}
                type="text"
                placeholder="Enter external link here (Optional)"
                className="w-100"
                onChange={(e) =>
                  setExternalLink(e.target.value === "" ? null : e.target.value)
                }
              />
            </Form.Group>
            {isPbi === true ? (
              <>
                {" "}
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Report ID</Form.Label>
                  <Form.Control
                    value={reportId || ""}
                    type="text"
                    placeholder="Enter reportId here"
                    className="w-100"
                    onChange={(e) =>
                      setReportId(e.target.value === "" ? null : e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Group ID</Form.Label>
                  <Form.Control
                    value={groupId || ""}
                    type="text"
                    placeholder="Enter groupId here"
                    className="w-100"
                    onChange={(e) =>
                      setGroupId(e.target.value === "" ? null : e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Page Name</Form.Label>
                  <Form.Control
                    value={pageName || ""}
                    type="text"
                    placeholder="Enter pageName here"
                    className="w-100"
                    onChange={(e) =>
                      setPageName(e.target.value === "" ? null : e.target.value)
                    }
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            {/* Submit & preview button */}
            <div
              className="position-absolute w-100"
              style={{
                bottom: 0,
                left: 0,
                padding: "1rem",
                backgroundColor: "white",
              }}
            >
              <Row className="justify-content-start">
                <Col xs="auto" className="d-flex flex-row gap-2">
                  <Button
                    variant="secondary"
                    className="eye-button"
                    disabled={
                      reportId === null || groupId === null || pageName === null
                    }
                    onClick={() => setShowPreview(true)}
                  >
                    <div className="eye">
                      <div className="shut">
                        <span></span>
                      </div>
                      <div className="ball"></div>
                    </div>
                    Preview
                  </Button>
                  <Button
                    variant="primary"
                    className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
                    type="submit"
                    disabled={isEditing || isCreatingPermission}
                  >
                    {isCreatingPermission ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Creating Permission
                      </>
                    ) : isEditing ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Editing
                      </>
                    ) : (
                      <>
                        <i className="ti-check" /> Submit
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <PowerbiPreview
        title={name}
        breadcrumbItem={name}
        sectionTitle={name}
        reportId={reportId as string}
        groupId={groupId as string}
        pageName={pageName as string}
        permission={permission}
        show={showPreview}
        handleClose={() => setShowPreview(false)}
      />
    </>
  )
}

export default EditMenuForm
