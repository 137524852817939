import React, { useState, useEffect } from "react"
import Select from "react-select"
import { SidebarItem, SearchProps, SelectOptions } from "../../shared/types"
import DanglingPermissions from "../Buttons/DanglingPermissions"

const SearchSidebar: React.FC<SearchProps> = ({ items, onSearch }) => {
  const [options, setOptions] = useState<SelectOptions[]>([])
  const [selectedOption, setSelectedOption] = useState<SelectOptions | null>(
    null
  )

  const setSearch = (items: SidebarItem[]) => {
    const selectOptions: { value: string; label: string }[] = []

    const addItemsToOptions = (items: SidebarItem[]) => {
      items.forEach((item) => {
        selectOptions.push({ value: item.id, label: item.name })
        if (item.subMenus) {
          addItemsToOptions(item.subMenus)
        }
      })
    }

    addItemsToOptions(items)
    setOptions(selectOptions)
  }

  /* set Options */
  useEffect(() => {
    setSearch(items)
  }, [items])

  const handleSearch = (selectedOption: SelectOptions | null) => {
    setSelectedOption(selectedOption)
    onSearch(selectedOption ? selectedOption.value : null)
  }
  return (
    <>
      {/* Search card */}
      <div className="d-flex w-100 p-3 align-items-center mt-2 bg-white gap-3 mb-3">
        <h5 className="h-100">Search Sidebar</h5>
        <Select
          className="w-75"
          options={options}
          onChange={handleSearch}
          value={selectedOption}
          isClearable
        />
        <DanglingPermissions />
      </div>
    </>
  )
}

export default SearchSidebar
