import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

interface PermissionAll {
  value: string
  description: string
}

export function useGetPermissions() {
  const request = useAxiosRequest()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [permissionList, setPermissionList] = useState<
    PermissionAll[] | string[]
  >()
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const fetchPermissions = useCallback(
    async (url: string) => {
      try {
        setIsLoading(true)
        const response = await request<any, PermissionAll[] | string[]>({
          url,
          method: "GET",
        })

        if (isMounted.current) {
          if (response && response.data) {
            setPermissionList(response.data)
          }
        }
      } catch (err: unknown) {
        let message = "Error fetching sidebar Items"
        if (axios.isAxiosError(err)) {
          const axiosErrorMessage = err?.response?.data?.error?.message
          message = axiosErrorMessage
            ? +message + ":" + axiosErrorMessage
            : message + "."
        } else if (err instanceof Error) {
          message = message + ": " + err.message
        } else {
          message = "."
        }
        if (isMounted.current) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 8000,
          })
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false)
        }
      }
    },
    [request]
  )

  const getAllPermission = useCallback(() => {
    fetchPermissions("api/v1/permissions-all")
  }, [fetchPermissions])

  const getPermissionsFromUser = useCallback(() => {
    fetchPermissions("api/v1/permissions")
  }, [fetchPermissions])

  return {
    getAllPermission,
    getPermissionsFromUser,
    permissionList,
    isLoading,
  }
}
