import BigNumber from "bignumber.js"
import {
    PortPredictorCargoGroupTypes,
    PortPredictorCargoesData,
    VesselActivity
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
    PortPredictorMarkerDeparture,
    PortType,
    VesselStateLadenOrBallast
} from "modules/PortPredictor/portPredictorReducer"
import {
    PortPredictorPortTypeSelectOption,
    portTypes,
} from "./PortPredictorArrivalInputs"




export function findVesselPortType(portType?: PortType) {
    if (!portType) {
        return
    }
    if (portType) {
        return portTypes.find((stop) => stop.value === portType)
    }
}

export function calcDaysFromDeparture({
    departureMarker,
    distanceFromDeparture,
}: {
    departureMarker: PortPredictorMarkerDeparture | undefined
    distanceFromDeparture: number | undefined
}) {
    if (
        !departureMarker ||
        !departureMarker.vesselSpeedSelected ||
        !distanceFromDeparture
    ) {
        return
    }
    const nauticalMiles = distanceFromDeparture
    const speed = departureMarker.vesselSpeedSelected
    const distanceTravelledPerDay = +speed * 24
    const daysFromDeparture = BigNumber(nauticalMiles / distanceTravelledPerDay)
        .decimalPlaces(0, BigNumber.ROUND_CEIL)
        .toNumber()

    return daysFromDeparture
}

export function getVesselStateForPortType({
    chosenPortType,
    currMarkerVesselState,
    previousMarkerVesselState,
}: {
    chosenPortType: PortPredictorPortTypeSelectOption | undefined
    currMarkerVesselState: VesselStateLadenOrBallast
    previousMarkerVesselState: VesselStateLadenOrBallast | undefined
}) {
    if (!chosenPortType) {
        return currMarkerVesselState
    }
    if (
        previousMarkerVesselState &&
        chosenPortType.activity === VesselActivity.Other
    ) {
        return previousMarkerVesselState
    }
    if (chosenPortType.activity === VesselActivity.Discharge) {
        return VesselStateLadenOrBallast.Ballast
    }
    if (chosenPortType.activity === VesselActivity.Load) {
        return VesselStateLadenOrBallast.Laden
    }
    return currMarkerVesselState
}

function removeDuplicatesFromOwnArray(arr: string[]) {
    return arr.filter((item, index) => arr.indexOf(item) === index)
}

export function groupCargoes(cargoes: PortPredictorCargoesData[]) {
    const cargoesWithMajorGroupFilledIn: PortPredictorCargoesData[] = []
    cargoes.forEach((item) => {
        if (item.major_group === "minor") {
            cargoesWithMajorGroupFilledIn.push({
                ...item,
                major_group: item.minor_group
            })
            return
        }
        cargoesWithMajorGroupFilledIn.push(item)
    })
    const majorGroupFiltered = removeDuplicatesFromOwnArray(
        cargoesWithMajorGroupFilledIn.map((cargo) => cargo.major_group)
    )

    const minorGroup = removeDuplicatesFromOwnArray(
        cargoes.map((cargo) => cargo.minor_group)
    )
    const minorGroupFiltered: string[] = []
    minorGroup.forEach((minorGroupItem) => {
        if (majorGroupFiltered.indexOf(minorGroupItem) < 0) {
            minorGroupFiltered.push(minorGroupItem)
        }
    })

    const commodity = removeDuplicatesFromOwnArray(
        cargoes.map((cargo) => cargo.commodity)
    )
    const commoditiesFiltered: string[] = []
    commodity.forEach((commodity) => {
        if (
            minorGroupFiltered.indexOf(commodity) < 0 &&
            majorGroupFiltered.indexOf(commodity) < 0
        ) {
            commoditiesFiltered.push(commodity)
        }
    })

    const result = {
        commodities: commoditiesFiltered.map((item) => {
            return { label: item, value: item, cargoGroup: PortPredictorCargoGroupTypes.Commodity }
        }),
        minor_group: minorGroupFiltered.map((item) => {
            return { label: item, value: item, cargoGroup: PortPredictorCargoGroupTypes.MinorGroup }
        }),
        major_group: majorGroupFiltered.map((item) => {
            return { label: item, value: item, cargoGroup: PortPredictorCargoGroupTypes.MajorGroup }
        }),
    }

    return result
}
