import React, { useState } from 'react';
import {  useAccordionButton } from 'react-bootstrap';
import { ChevronRight } from 'lucide-react';
import './assets/sortableItem.css';
import { AccordionButtonProps } from '../../shared/types';


const AccordionButton: React.FC<AccordionButtonProps> = ({ eventKey, className }) => {      
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIsOpen(!isOpen);
  });

  return (
    <button
      type="button"
      className={className}
      onClick={decoratedOnClick}
    >
      <ChevronRight className={`arrow-icon ${isOpen ? 'rotate' : ''}`} />
    </button>
  );
};

export default AccordionButton;
