import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { EditMenuParams } from "../shared/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"

interface UseEditMenuArgs {
  handleEditMenu?: (data: any) => void
}

export function useEditMenu({ handleEditMenu }: UseEditMenuArgs) {
  const request = useAxiosRequest()
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = true
    }
    return () => {
      isMounted.current = false
    }
  }, [])

  async function editMenu(params: EditMenuParams) {
    try {
      setIsEditing(true)
      const response = await request<any, unknown>({
        url: `api/v1/sidebar/single`,
        method: "PUT",
        data: params,
      })

      if (handleEditMenu) {
        handleEditMenu(response?.data)
      }
      toast.success("Edit menu success", {
        position: "bottom-right",
        autoClose: 8000,
      })
      queryClient.invalidateQueries(["sidebarItems"])
    } catch (err: unknown) {
      let message = "Error editing menu"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      if (isMounted.current) {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      setIsEditing(false)
    }
  }

  async function BulkUpdateMenu(params: EditMenuParams[]) {
    try {
      setIsEditing(true)
      const response = await request<any, unknown>({
        url: `api/v1/sidebar/bulk`,
        method: "PUT",
        data: params,
      })

      if (handleEditMenu) {
        handleEditMenu(response?.data)
      }
      toast.success("Updated Changes", {
        position: "bottom-right",
        autoClose: 8000,
      })
      queryClient.invalidateQueries(["sidebarItems"])
    } catch (err: unknown) {
      let message = "Error editing menu"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      if (isMounted.current) {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      setIsEditing(false)
    }
  }
  return { editMenu, isEditing, BulkUpdateMenu }
}
