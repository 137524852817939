import BigNumber from "bignumber.js"
import {
  PortPredictorPortsData,
  PortPredictorVesselSpeed,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
  MarkerType,
  PortPredictorJourneyState,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  PortType,
  VesselStateLadenOrBallast,
} from "modules/PortPredictor/portPredictorReducer"
import moment from "moment"
import { SpeedDataAvgKey, VesselSpeedDataWithAverages, getVesselSpeedDataWithAverages } from "../PortPredictorDeparture/helpers"

export function getVesselStateFromPortType(portType: PortType) {
  if (
    portType === PortType.Discharge ||
    portType === PortType.DischargeWithArrivalWindow
  ) {
    return VesselStateLadenOrBallast.Ballast
  }
  return VesselStateLadenOrBallast.Laden
}

export function getVesselStateForDeparture(prevMarker: PortPredictorMarkerArrival) {
  if (prevMarker.portType === PortType.Discharge) {
    return VesselStateLadenOrBallast.Ballast
  }
  if (prevMarker.portType === PortType.Load) {
    return VesselStateLadenOrBallast.Laden
  }

  return prevMarker.vesselState
}

export function createDepartureMarker({
  prevMarker,
  speedDataWithAvg
}: {
  prevMarker: PortPredictorMarkerArrival
  speedDataWithAvg: VesselSpeedDataWithAverages | undefined
}): PortPredictorMarkerDeparture | undefined {
  if (!prevMarker) {
    return
  }

  let date
  if (prevMarker.turnAroundTime) {
    const daysToAdd = BigNumber(prevMarker.turnAroundTime)
      .decimalPlaces(0, BigNumber.ROUND_CEIL)
      .toFixed()
    date = moment(prevMarker.arrivalDate).add(daysToAdd, "days").format("YYYY-MM-DD")
  }

  const defaultSpeedKey =
    prevMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? speedDataWithAvg?.laden.defaultMonthsKey
      : speedDataWithAvg?.ballast.defaultMonthsKey

  const vesselState = prevMarker.vesselState.toLowerCase() as "laden" | "ballast"

  const vesselSpeedSelected = speedDataWithAvg?.[vesselState][defaultSpeedKey as SpeedDataAvgKey]?.avg

  return {
    id: prevMarker.id + 1,
    date,
    markerType: MarkerType.Departure,
    cargo: prevMarker.cargo,
    cargoGroup: prevMarker.cargoGroup,
    distanceToArrival: undefined, // Don't know until following arrival marker is created
    daysToArrival: undefined,
    posWkt: prevMarker.port ? (prevMarker.port as PortPredictorPortsData).pos_wkt : undefined,
    vesselSpeedSelected: vesselSpeedSelected,
    vesselState: getVesselStateForDeparture(prevMarker),
    port: prevMarker.port as PortPredictorPortsData,
    vesselMonthsAvgSelected:  undefined,
    vesselSeaMargin:  undefined
  }
}

export function findVesselPortTypeWithPrevMarker(prevMarker: PortPredictorMarkerDeparture) {
  if (prevMarker.vesselState === VesselStateLadenOrBallast.Ballast) {
    return PortType.LoadWithLaycan
  }

  return PortType.Discharge
}

export function createArrivalMarker(
  prevMarker: PortPredictorMarkerDeparture
): PortPredictorMarkerArrival {
  const portType = findVesselPortTypeWithPrevMarker(prevMarker)

  const cargo =
    prevMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? prevMarker.cargo
      : undefined

  const cargoGroup =
    prevMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? prevMarker.cargoGroup
      : undefined

  return {
    id: prevMarker.id + 1,
    arrivalDate: undefined,
    vesselState: getVesselStateFromPortType(portType),
    portType,
    markerType: MarkerType.Arrival,
    cargo: cargo,
    cargoGroup: cargoGroup,
    port: undefined,
    berth: undefined,
    turnAroundTime: undefined,
    distanceFromDeparture: undefined,
    daysFromDeparture: undefined,
  }
}

// TODO: Test
export function createMarkerBase({
  journeyMarkerState,
  chosenVesselSpeedData,
}: {
  journeyMarkerState: PortPredictorJourneyState
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
}) {
  const prevMarker = journeyMarkerState[journeyMarkerState.length - 1]
  const speedDataWithAvg = getVesselSpeedDataWithAverages(chosenVesselSpeedData)

  if (prevMarker.markerType === MarkerType.Departure) {
    const arrivalMarker = createArrivalMarker(prevMarker)
    const departureMarker = createDepartureMarker({
      prevMarker: arrivalMarker,
      speedDataWithAvg
    })
    return [arrivalMarker, departureMarker]

  }
}
