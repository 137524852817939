import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"
import { PortPredictorTurnAroundTimeData } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { Form } from "react-bootstrap"

export enum TypeOfTurnAroundTimeData {
  UsePortData = "use-port-data",
  UseTerminalData = "use-terminal-data",
}

interface PortOrTerminalTurnAroundTimeCheckBoxesProps {
  portTurnAroundTimeData: PortPredictorTurnAroundTimeData[] | null | undefined
  terminalTurnAroundTimeData:
    | PortPredictorTurnAroundTimeData[]
    | null
    | undefined
  typeOfTurnAroundTimeData: TypeOfTurnAroundTimeData
  setTypeOfTurnAroundTimeData: (value: TypeOfTurnAroundTimeData) => void
  setTurnAroundTimeData: (data: PortPredictorTurnAroundTimeData[]) => void
  setTurnAroundTime: (selectId: TypeOfTurnAroundTimeData) => void
}

export function PortOrTerminalTurnAroundTimeCheckBoxes({
  portTurnAroundTimeData,
  terminalTurnAroundTimeData,
  typeOfTurnAroundTimeData,
  setTypeOfTurnAroundTimeData,
  setTurnAroundTimeData,
  setTurnAroundTime,
}: PortOrTerminalTurnAroundTimeCheckBoxesProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  return (
    <div className="d-flex align-items-center justify-content-end ms-5 mt-3">
      {[
        {
          label: "Use Port Data",
          value: TypeOfTurnAroundTimeData.UsePortData,
          isDisabled: !portTurnAroundTimeData,
        },
        {
          label: "Use Terminal Data",
          value: TypeOfTurnAroundTimeData.UseTerminalData,
          isDisabled: !terminalTurnAroundTimeData,
        },
      ].map((selection) => {
        return (
          <Form.Check
            className="PortPredictor_TurnAroundTimeCheckboxes_Wrapper d-flex justify-content-end"
            type="checkbox"
            id={String(selection.value)}
            key={String(selection.value)}
            disabled={!getTabStateKeyValue('isEditMode')}
          >
            <Form.Check.Input
              disabled={selection.isDisabled || !getTabStateKeyValue('isEditMode')}
              type="checkbox"
              checked={typeOfTurnAroundTimeData === selection.value}
              onChange={(event) => {
                setTypeOfTurnAroundTimeData(
                  event.target.id as TypeOfTurnAroundTimeData
                )
                setTurnAroundTime(event.target.id as TypeOfTurnAroundTimeData)
                if (
                  event.target.id ===
                    TypeOfTurnAroundTimeData.UseTerminalData &&
                  terminalTurnAroundTimeData
                ) {
                  setTurnAroundTimeData(terminalTurnAroundTimeData)
                }
                if (
                  event.target.id === TypeOfTurnAroundTimeData.UsePortData &&
                  portTurnAroundTimeData
                ) {
                  setTurnAroundTimeData(portTurnAroundTimeData)
                }
              }}
            />
            <Form.Check.Label style={{ width: "unset" }} className="m-0 ms-1">
              {selection.label}
            </Form.Check.Label>
          </Form.Check>
        )
      })}
    </div>
  )
}
