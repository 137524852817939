import { X } from "lucide-react"
import React, { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap"
import IconSelect from "../Selects/IconSelect"
import Select, { SingleValue } from "react-select"
import PermissionSelect from "../Selects/PermissionSelect"
import { useCreateMenu } from "../../hooks/useCreateMenu"
import { useCreatePermission } from "../../hooks/useCreatePermission"
import PowerbiPreview from "./PowerbiPreview"
import { CREATE_PERMISSION } from "../../shared/libs"
import { toast } from "react-toastify"
import {
  PERMISSION_CREATE_MENU_PBI,
  selectBoolOptions,
  selectBoolOptionsPbi,
  PARENT_MENU_PLACEHOLDER
} from "../../shared/libs"
import "./assets/eye.css"
import {
  CreateMenuParams,
  MenuFormProps,
  SelectProps,
} from "../../shared/types"

const MenuForm: React.FC<MenuFormProps> = ({
  show,
  handleClose,
  parentId,
  parentName,
  parentPath,
  permissions,
}) => {
  const [path, setPath] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [icon, setSelectedIcon] = useState<string | null>(null)
  const [permission, setSelectedPermission] = useState<string>("")
  const [externalLink, setExternalLink] = useState<string | null>(null)
  const [newPermission, setNewPermission] = useState<string | null>(null)
  const [reportId, setReportId] = useState<string | null>(null)
  const [groupId, setGroupId] = useState<string | null>(null)
  const [pageName, setPageName] = useState<string | null>(null)
  const [pbiAdmin, setPbiAdmin] = useState<boolean>(false)
  const [isPbi, setIsPbi] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [isPageValue, setIsPageValue] = useState<boolean>(false)

  /* for select - singlevalue */
  const [blankSingleValue, setBlankSingleValue] =
    useState<SingleValue<SelectProps>>()
  const { createMenu, createSubMenu, isLoading } = useCreateMenu({})
  const { createPermission, isCreatingPermission } = useCreatePermission()

  const handleIconChange = (icon: string | null) => {
    setSelectedIcon(icon)
  }
  const handlePermissionChange = (permission: string) => {
    setSelectedPermission(permission)
  }

  const handleChangeSelectPBI = (selectedOption: SingleValue<SelectProps>) => {
    if (selectedOption) {
      setIsPbi(selectedOption.value)
      if (selectedOption.value === true) {
        setIsPageValue(true)
      }
    }
  }

  useEffect(() => {
    if (permissions.includes(PERMISSION_CREATE_MENU_PBI)) {
      setPbiAdmin(true)
      setIsPbi(true)
      setIsPageValue(true)
    }
  }, [permissions])

  const handleChangeSelectIsPage = (
    selectedOption: SingleValue<SelectProps>
  ) => {
    if (selectedOption) {
      setIsPageValue(selectedOption.value)
      setReportId(null)
      setGroupId(null)
      setPageName(null)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    //check if user follows parentpath

    if (parentPath != PARENT_MENU_PLACEHOLDER && !path.startsWith(parentPath+'/')){
      toast.error('Path must follow parent path', {
        position: "bottom-right",
        autoClose: 8000,
      })
      return // Prevent submission
    }

    // Define the expected prefix based on parentPath
    let expectedPrefix = ""
    let permissionCompare = ""

    if (newPermission !== null && permission === CREATE_PERMISSION) {
      permissionCompare = newPermission
    }
    else{
      permissionCompare = permission
    }

    if (parentPath === "/freight-optimizer") {
      expectedPrefix = "read:fop"
    } else if (parentPath === "/freight-matrix") {
      expectedPrefix = "read:freight-matrix"
    } else if (parentPath === "/chartering-insights") {
      expectedPrefix = "read:ci"
    }

    // Check if permission starts with the expected prefix
    if (expectedPrefix && !permissionCompare.startsWith(expectedPrefix)) {
      toast.error(
        `Permission must start with "${expectedPrefix}" for this parent path`,
        {
          position: "bottom-right",
          autoClose: 8000,
        }
      )
      return // Prevent submission
    }

    const menuItem: CreateMenuParams = {
      path,
      name,
      icon,
      isPage: isPageValue,
      permission: permission,
      externalLink,
      reportId,
      groupId,
      pageName,
      parentId: parentId,
    }
    try {
      if (newPermission !== null && permission === CREATE_PERMISSION) {
        await createPermission({ permission: newPermission })
        menuItem.permission = newPermission
        await createMenu(menuItem)
      } else if (menuItem.parentId === null) {
        await createMenu(menuItem)
      } else {
        await createSubMenu(menuItem)
      }
    } catch (error: unknown) {
      toast.error("Failed to create menu", {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setPath("")
      setName("")
      setSelectedIcon(null)
      setIsPageValue(false)
      setIsPbi(false)
      setExternalLink(null)
      setReportId(null)
      setGroupId(null)
      setPageName(null)
      handleClose()
    }
  }

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Header */}
          <div className="d-flex flex-row align-items-center justify-content-between mt-3 px-2 mb-3">
            <h5>{parentId ? <>Add Submenu - {parentName}</> : "Add Menu"}</h5>
            <button
              className="border-none"
              onClick={handleClose}
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <X className="text-muted" />
            </button>
          </div>

          {/* Forms */}
          <Form onSubmit={handleSubmit}>
            {/* Path */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Path</Form.Label>
              <Form.Control
                type="text"
                placeholder={parentPath}
                className="w-100"
                required
                onChange={(e) => setPath(e.target.value)}
              />
            </Form.Group>

            {/* Name */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name here..."
                className="w-100"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            {/* Icon */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Icon</Form.Label>
              <IconSelect onChange={handleIconChange} />
            </Form.Group>

            {/* Permissions */}
            <Form.Group className="mb-3 px-1">
              <Form.Label>Permissions</Form.Label>
              <PermissionSelect onChange={handlePermissionChange} />
            </Form.Group>

            {/* Create new Permission */}
            {permission === CREATE_PERMISSION ? (
              <>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">New Permission</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter permission name here..."
                    className="w-100"
                    required
                    onChange={(e) => setNewPermission(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <div className="d-flex flex-row justify-content-between w-100">
              {/* ispage */}
              <Form.Group className="mb-3 px-1 w-100">
                <Form.Label>isPage</Form.Label>
                <Select
                  placeholder="Yes / No"
                  onChange={handleChangeSelectIsPage}
                  options={pbiAdmin ? selectBoolOptionsPbi : selectBoolOptions}
                  value={pbiAdmin ? selectBoolOptions[0] : blankSingleValue}
                />
              </Form.Group>

              {/* isPbi */}
              <Form.Group className="mb-3 px-1 w-100">
                <Form.Label>isPbi</Form.Label>
                <Select
                  placeholder="Yes / No"
                  onChange={handleChangeSelectPBI}
                  options={pbiAdmin ? selectBoolOptionsPbi : selectBoolOptions}
                  value={pbiAdmin ? selectBoolOptions[0] : blankSingleValue}
                />
              </Form.Group>
            </div>

            {/* ExternalLink */}
            <Form.Group className="mb-3 px-1">
              <Form.Label className="w-100">External Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter external link here (Optional)"
                className="w-100"
                onChange={(e) => setExternalLink(e.target.value)}
              />
            </Form.Group>
            {isPbi === true ? (
              <>
                {" "}
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Report ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter reportId here"
                    className="w-100"
                    onChange={(e) => setReportId(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Group ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter groupId here"
                    className="w-100"
                    onChange={(e) => setGroupId(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 px-1">
                  <Form.Label className="w-100">Page Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter pageName here"
                    className="w-100"
                    onChange={(e) => setPageName(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            {/* Submit & preview button */}
            <div
              className="position-absolute w-100"
              style={{
                bottom: 0,
                left: 0,
                padding: "1rem",
                backgroundColor: "white",
              }}
            >
              <Row className="justify-content-start">
                <Col xs="auto" className="d-flex flex-row gap-2">
                  <Button
                    variant="secondary"
                    className="eye-button"
                    disabled={
                      reportId === null || groupId === null || pageName === null
                    }
                    onClick={() => setShowPreview(true)}
                  >
                    <div className="eye">
                      <div className="shut">
                        <span></span>
                      </div>
                      <div className="ball"></div>
                    </div>
                    Preview
                  </Button>
                  <Button
                    variant="primary"
                    className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
                    type="submit"
                    disabled={isLoading || isCreatingPermission}
                  >
                    {isCreatingPermission ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Creating permission
                      </>
                    ) : isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Creating
                      </>
                    ) : (
                      <>
                        <i className="ti-check" /> Submit
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Preview Modal */}
      <PowerbiPreview
        title={name}
        breadcrumbItem={name}
        sectionTitle={name}
        reportId={reportId as string}
        groupId={groupId as string}
        pageName={pageName as string}
        permission={permission}
        show={showPreview}
        handleClose={() => setShowPreview(false)}
      />
    </>
  )
}

export default MenuForm
