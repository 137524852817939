import { SidebarItem } from "./types"

export function flattenSidebarItems(items: SidebarItem[]): SidebarItem[] {
  return items.reduce<SidebarItem[]>((acc, item) => {
    acc.push(item)
    if (item.subMenus) {
      acc.push(...flattenSidebarItems(item.subMenus))
    }
    return acc
  }, [])
}
