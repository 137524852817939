import React, { createContext, Dispatch, ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { InitialItineraryState, ItineraryActions, ItineraryReducer, ItineraryState, UPDATE_ITINERARY } from '../components/PortPredictorItinerary/ItineraryReducer'
import { Itinerary, Markers } from './useItineraryAPI'

// Define the shape of the context value
interface ItineraryContextType {
  itineraryState: ItineraryState
  setItineraryState: (value: ItineraryState) => void
  getItineraryMarker: (id: number) => Markers | undefined
  itineraryDispatch: Dispatch<ItineraryActions>

  getTabStateKeyValue: (key: keyof Itinerary) => boolean
  setTabStateKeyValue: (key: keyof Itinerary, value: boolean) => void
}

// Create the Context with an initial value of undefined
const ItineraryContext = createContext<ItineraryContextType | undefined>(undefined)

// Props for ItineraryProvider
interface ItineraryProviderProps {
  children: ReactNode
}

export const ItineraryProvider: React.FC<ItineraryProviderProps> = ({ children }) => {
  const [reducerItineraryState, itineraryDispatch] = useReducer(
    ItineraryReducer,
    InitialItineraryState
  )

  useEffect(() => {
    if (reducerItineraryState) {
      setItineraryState(reducerItineraryState)
    }
  }, [reducerItineraryState])

  const [itineraryState, setItineraryState] = useState<ItineraryState>({} as ItineraryState)

  const GetSelectedItinerary = useCallback((id: number): Itinerary | undefined => {
    return itineraryState && itineraryState.itineraries?.find(
      (item) => item.id === id
    );
  }, [itineraryState]);

  const getItineraryMarker = (id: number) => {
    if (itineraryState) {
      const currentItinerary = GetSelectedItinerary(id)
      if (currentItinerary) {
        return currentItinerary?.markers
      }
    }
  }

  const getTabPropValue = (key: keyof Itinerary): boolean => {
    if (itineraryState) {
      const currentItinerary = GetSelectedItinerary(itineraryState.selectedId)
      if (!currentItinerary) {
        return false
      } else {
        return !!currentItinerary[key]
      }
    } else {
      return false
    }
  }

  const getTabStateKeyValue = (key: keyof Itinerary) => {
    return getTabPropValue(key)
  }
  const setTabStateKeyValue = (key: keyof Itinerary, value: boolean) => {
    itineraryDispatch({
      type: UPDATE_ITINERARY,
      payload: {
        id: itineraryState.selectedId,
        [key]: value,
      },
    })
  }
  return (
    <ItineraryContext.Provider value={{ itineraryState, setItineraryState, getItineraryMarker, itineraryDispatch, getTabStateKeyValue, setTabStateKeyValue }}>
      {children}
    </ItineraryContext.Provider>
  )
}

// Custom hook to use the ItineraryContext
export const useItineraryContext = (): ItineraryContextType => {
  const context = useContext(ItineraryContext)
  if (!context) {
    throw new Error('useItineraryContext must be used within an ItineraryProvider')
  }
  return context
}
