import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import { useQueryClient } from "@tanstack/react-query"

interface CreateMenuParams {
  path: string
  name: string
  icon: string | null
  isPage: boolean
  permission: string
  externalLink: string | null
  reportId: string | null
  groupId: string | null
  pageName: string | null
  parentId: string | null
}

interface UseCreateMenuArgs {
  handleCreateMenu?: (data: any) => void
}

export function useCreateMenu({ handleCreateMenu }: UseCreateMenuArgs) {
  const request = useAxiosRequest()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function createMenu(params: CreateMenuParams) {
    try {
      setIsLoading(true)
      const response = await request<any, unknown>({
        url: "api/v1/sidebar/single",
        method: "POST",
        data: params,
      })

      if (handleCreateMenu) {
        handleCreateMenu(response?.data)
      }

      toast.success("Menu created successfully", {
        position: "bottom-right",
        autoClose: 8000,
      })
      queryClient.invalidateQueries(["sidebarItems"])
    } catch (err: unknown) {
      let message = "Error creating menu"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        const statusCode = err?.response?.status
        if (statusCode === 409) {
          message = "Maintenance overlaps with existing event"
        } else {
          message = axiosErrorMessage
            ? message + ": " + axiosErrorMessage
            : message + "."
        }
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      toast.error(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function createSubMenu(params: CreateMenuParams) {
    try {
      setIsLoading(true)
      const response = await request<any, unknown>({
        url: "api/v1/sidebar/single-sub",
        method: "POST",
        data: params,
      })

      if (handleCreateMenu) {
        handleCreateMenu(response?.data)
      }

      toast.success("Menu created successfully", {
        position: "bottom-right",
        autoClose: 8000,
      })
      queryClient.invalidateQueries(["sidebarItems"])
    } catch (err: unknown) {
      let message = "Error creating menu"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        const statusCode = err?.response?.status
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      toast.error(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { createMenu, createSubMenu, isLoading }
}
