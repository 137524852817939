import { BASE_URL } from 'api/url_helper';
// import axios from 'axios';
import { useAxiosRequest } from "api/axiosRequest";
import { Itineraries, Itinerary } from './useItineraryAPI';
// const API_BASE_URL = `${BASE_URL}/port-predictor/`;

const ItineraryService = () => {
    const request = useAxiosRequest()
    const getItineraries = async () => {
        try {
            const itinerariesUrl = `${BASE_URL}/port-predictor/itinerary/user`
            const response = await request<any, Itineraries>(
                {
                    url: itinerariesUrl,
                    method: "GET"
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const getItineraryByUUID = async (uuid: string, includeMarkers: boolean | null) => {
        try {
            if (uuid) {
                const itineraryUrl = `${BASE_URL}/port-predictor/itinerary?uuid=${uuid}${includeMarkers ? '&includeMarkers=true' : ''}`
                const response = await request<any, Itineraries>(
                    {
                        url: itineraryUrl,
                        method: "GET"
                    }
                );
                return response && response.data;
            }
            return {}

        } catch (error) {
            throw error;
        }
    };


    const createItinerary = async (newItinerary: Itinerary) => {
        try {
            const response = await request<any, Itinerary>(
                {
                    url: `${BASE_URL}/port-predictor/itinerary`,
                    method: "POST",
                    data: newItinerary
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const updateItinerary = async (id: number, updatedItinerary: Itinerary) => {
        try {
            const response = await request<any, Itinerary>(
                {
                    url: `${BASE_URL}/port-predictor/itinerary`,
                    method: "PUT",
                    data: updatedItinerary
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteItinerary = async (uuid: string) => {
        try {
            const response = await request<any, Itinerary>(
                {
                    url: `${BASE_URL}/port-predictor/itinerary?uuid=${uuid}`,
                    method: "DELETE",
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getItineraries,
        getItineraryByUUID,
        createItinerary,
        updateItinerary,
        deleteItinerary
    }
}

export default ItineraryService
