import { ErrorBoundary } from "ErrorBoundary"
import { Map as LeafletMap } from "leaflet"
import { fixAntimeridian } from "modules/MapComponent/components/map/positions"
import { useFetchVesselPositions } from "modules/PortPredictor/hooks/useFetchVesselPositions"
import { useGetVessels } from "modules/VesselSearch/useGetVessels"
import { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { GetFromLocalStorage } from "user/userSlice"
import {
  VesselSearch,
  VesselSegmentInputSelection,
} from "../../VesselSearch/VesselSearchMain"
import { formatVesselNamesForDropdown } from "../helpers/formatVesselNamesForDropdown"
import { getMarkerPointsFromJourneyMarkerState } from "../helpers/mapHelpers"
import { useFetchFuturePath } from "../hooks/useFetchFuturePath"
import { useFetchVesselJourneySpeed } from "../hooks/useFetchVesselJourneySpeed"
import { useFetchVesselJourneyStart } from "../hooks/useFetchVesselJourneyStart"
import { Markers } from "../hooks/useItineraryAPI"
import { useJourneyStart } from "../hooks/useJourneyStart"
import { PortPredictorVesselStateDataWithMajorCargo } from "../hooks/usePortPredictorAPI"
import {
  ADD_JOURNEY_START_MARKERS,
  EDIT_JOURNEY,
  EXTEND_JOURNEY_MARKERS,
  MarkerType,
  PortPredictorJourneyMarker,
  portPredictorJourneyReducer,
  PortPredictorJourneyState,
} from "../portPredictorReducer"
import { ErrorFallback } from "./PortPredictorErrorFallback"
import { CapitalizeFirstLetter, DeepEqualIgnoreNullUndefined } from "./PortPredictorItinerary/Utilities"
import { PortPredictorMap } from "./PortPredictorMap"
import { PortPredictorSpeedGraphModal } from "./PortPredictorSpeedGraphModal"
import { PortPredictorTimeline } from "./PortPredictorTimeline/PortPredictorTimeline"
import { useFetchVesselDetails } from "../hooks/useFetchVesselDetails"
import { useFetchVesselStays } from "../hooks/useFetchVesselStays"
import { useItineraryContext } from "../hooks/itineraryContext"
import {
  useWhatChanged,
} from '@simbathesailor/use-what-changed';


export interface VesselListSingle {
  imo: number
  segment: string
  vessel_name: string
}

export interface MarkerPoint {
  journeyMarkerStateIndex?: number
  journeyMarkerStateLocation?: string
  port?: string
  lat: number
  lon: number
}


export interface MarkerLatLon {
  lat: number
  lon: number
  markerId?: number
}


export function PortPredictor({
  updatePortPortalItineraryMarkers,
  initialSavedMarkers,
  selectedId,
  keyState
}: {
  updatePortPortalItineraryMarkers: (
    itineraryMarkers: Markers,
    selectedId: number
  ) => void
  initialSavedMarkers: Markers | undefined
  selectedId: number,
  keyState: string
}) {
  const { getTabStateKeyValue, itineraryState } = useItineraryContext()

  /**
   * Itinerary sections
   */
  const [savedMarkers, setSavedMarkersData] = useState(initialSavedMarkers)
  const selectedIdRef = useRef(selectedId)


  /**
   * Segment
   */
  const user = useSelector((state: RootState) => state.user)

  const vesselSegments = user.chosenOrganization?.segments

  const defaultSegment = user.defaultSegment

  const [chosenSegment, setChosenSegment] = useState<VesselSegmentInputSelection | undefined>(
    defaultSegment
      ? {
        label: CapitalizeFirstLetter(
          GetFromLocalStorage("preferredSegment") // || user.preferredSegment || ""
        ),
        value: GetFromLocalStorage("preferredSegment")
      }
      : undefined
  )

  /**
   * Chosen Vessel
   */
  const [chosenVesselImo, setChosenVesselImo] = useState<number | undefined>()
  const [chosenVesselName, setChosenVesselName] = useState<string | undefined>()
  const { vesselsData, getVessels, getVesselsLoading } = useGetVessels({
    defaultSegment: chosenSegment?.value || defaultSegment || undefined,
  })

  const {
    isChosenVesselStateLoading,
    chosenVesselStateData,
    isChosenVesselStateSuccess,
  } = useFetchVesselJourneyStart(chosenVesselImo, (initialSavedMarkers?.meta?.data && ('chosenVesselStateData' in initialSavedMarkers?.meta?.data)) ? initialSavedMarkers?.meta?.data['chosenVesselStateData'] : null)

  const { isChosenVesselSpeedSuccess, chosenVesselSpeedData } =
    useFetchVesselJourneySpeed(chosenVesselImo, (initialSavedMarkers?.meta?.data && ('chosenVesselSpeedData' in initialSavedMarkers?.meta?.data)) ? initialSavedMarkers?.meta?.data['chosenVesselSpeedData'] : null)

  /**
   * Journey
   */
  const [journeyMarkerState, journeyMarkerDispatch] = useReducer(
    portPredictorJourneyReducer,
    []
  )

  const addJourneyStartMarkersArr = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: ADD_JOURNEY_START_MARKERS,
        payload: markers,
      })
    },
    [journeyMarkerDispatch]
  )

  const { journeyStartMarkersArr } = useJourneyStart({
    chosenVesselStateData,
    chosenVesselImo,
    journeyMarkerState,
    chosenVesselSpeedData,
    isChosenVesselSpeedSuccess,
    isChosenVesselStateSuccess,
    addJourneyStartMarkersArr,
    isStartOfJourney: journeyMarkerState.length === 0,
  })

  const dispatchEditJourney = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: EDIT_JOURNEY,
        payload: markers,
      })
    },
    []
  )
  const dispatchAddJourneyStartMarkers = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: ADD_JOURNEY_START_MARKERS,
        payload: markers,
      })
    },
    []
  )
  const dispatchExtendJourneyMarkers = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: EXTEND_JOURNEY_MARKERS,
        payload: markers,
      })
    },
    []
  )

  const [isStartOfJourney, setIsStartOfJourney] = useState(
    (!initialSavedMarkers || initialSavedMarkers.data?.length === 0) &&
    journeyMarkerState.length === 0
  )

  /**
   * Itinerary
   */

  /**
  * Chosen Vessel
  */
  const curPrefferedSegment = useRef(chosenSegment)
  useEffect(() => {
    let curSegment = chosenSegment
    let curVesselImo = chosenVesselImo
    let curVesselName = chosenVesselName
    if (selectedIdRef.current !== selectedId) {
      curVesselImo = undefined
      curVesselName = undefined

      selectedIdRef.current = selectedId
    }

    if (
      !initialSavedMarkers ||
      !(initialSavedMarkers.meta && initialSavedMarkers.data)
    ) {
      setChosenSegment(curSegment)
      setChosenVesselImo(curVesselImo)
      setChosenVesselName(curVesselName)
    } else {
      // Check for saved metadata
      if (initialSavedMarkers.meta) {
        // Check for saved segment
        if (initialSavedMarkers.meta.segment) {
          const savedSegment = initialSavedMarkers.meta.segment
          setChosenSegment(savedSegment.value ? savedSegment : ({ label: defaultSegment, value: defaultSegment } as VesselSegmentInputSelection))
          getVessels({ segment: savedSegment.value ? savedSegment.value : defaultSegment! })
        } else {
          setChosenSegment(curSegment)
        }

        // Check for saved Vessel (IMO and Name)
        if (initialSavedMarkers.meta.vessel) {
          const savedVessel = initialSavedMarkers.meta.vessel
          if (savedVessel.value) {
            setChosenVesselImo(parseInt(savedVessel.value))
          } else {
            setChosenVesselImo(curVesselImo)
          }

          if (savedVessel.label) {
            setChosenVesselName(savedVessel.label)
          } else {
            setChosenVesselName(curVesselName)
          }
        } else {
          setChosenVesselImo(curVesselImo)
          setChosenVesselName(curVesselName)
        }
      }
      // check for saved markers
      if (initialSavedMarkers.data && (initialSavedMarkers.data.length > 0 || getTabStateKeyValue('isNew'))) {
        journeyMarkerDispatch({
          type: "INITIALIZE_JOURNEY_MARKER",
          payload: initialSavedMarkers.data,
        })
      }

      if (curSegment && curPrefferedSegment.current !== curSegment) {
        getVessels({ segment: curSegment.value ? curSegment.value : defaultSegment! })
        curPrefferedSegment.current = curSegment
      }
    }
  }, [initialSavedMarkers, keyState, selectedId])

  useEffect(() => {
    const markers: Markers = {
      data: [...journeyMarkerState],
      meta: {
        organization: user.chosenOrganization?.name || "",
        segment: chosenSegment!,
        vessel: { label: chosenVesselName || "", value: chosenVesselImo! + "" },
        data: {
          chosenVesselStateData,
          chosenVesselSpeedData,
        },
      },
    }
    updatePortPortalItineraryMarkers(markers, selectedId)

    if (journeyMarkerState && journeyMarkerState.length > 0)
      setIsStartOfJourney(false)
  }, [journeyMarkerState, selectedId, chosenSegment, keyState, chosenVesselImo, chosenVesselStateData, chosenVesselSpeedData])

  /**
   * SpeedGraph
   */
  const [isSpeedGraphModalShown, setIsSpeedGraphModalShown] = useState(false)

  /**
   * Map
   */
  const [map, setMap] = useState<LeafletMap | null>(null)
  const { vesselDetailsData } = useFetchVesselDetails(chosenVesselImo)
  const { vesselStaysData } = useFetchVesselStays({
    imo: chosenVesselImo,
    built: vesselDetailsData?.built,
  })

  const { futurePathData } = useFetchFuturePath(
    getMarkerPointsFromJourneyMarkerState(journeyMarkerState)
  )
  const { vesselPositions } = useFetchVesselPositions({
    imo: chosenVesselImo,
    months: 6,
  })

  return (
    <>
      {chosenVesselSpeedData && chosenVesselName && isSpeedGraphModalShown && (
        <PortPredictorSpeedGraphModal
          isSpeedGraphModalShown={isSpeedGraphModalShown}
          chosenVesselSpeedData={chosenVesselSpeedData}
          chosenVesselName={chosenVesselName}
          closeSpeedGraphModal={() => {
            setIsSpeedGraphModalShown(false)
          }}
        />
      )}
      <Card>
        <Card.Body>
          <div className="VesselSearch_Row">
            {(defaultSegment && chosenSegment && vesselSegments) ? (
              <VesselSearch
                vesselsData={formatVesselNamesForDropdown(vesselsData)}
                defaultSegment={chosenSegment.value ? chosenSegment! : {
                  label: defaultSegment,
                  value: defaultSegment,
                }}
                getVesselsLoading={getVesselsLoading}
                getChosenVesselLoading={isChosenVesselStateLoading}
                chosenSegment={chosenSegment.value ? chosenSegment! : {
                  label: defaultSegment,
                  value: defaultSegment,
                }}
                getChosenVessel={({
                  name,
                  imo,
                }: {
                  imo: number
                  name: string
                }) => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                  setChosenVesselName(name)
                  setChosenVesselImo(imo) // Set on main component
                }}
                setChosenSegment={(segment: VesselSegmentInputSelection) => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                  setChosenSegment(segment)
                }}
                getVessels={getVessels}
                vesselSegments={vesselSegments.map((segment) => {
                  return { label: segment, value: segment }
                })}
                defaultVessel={{
                  label: chosenVesselName || "",
                  value: chosenVesselImo ? chosenVesselImo?.toString() : "",
                }}
              />
            ) : ""}
            <div className="VesselSearch_ButtonContainer">
              <Button
                disabled={!getTabStateKeyValue('isEditMode')}
                onClick={() => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                }}
                className="me-1 PortPredictor_VesselSearch_Button w-100"
                color="primary"
              >
                Reset
              </Button>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <>
                {(chosenVesselImo &&
                  chosenSegment &&
                  chosenVesselStateData &&
                  vesselStaysData &&
                  vesselPositions) ? (
                  <PortPredictorMap
                    chosenVesselStateData={chosenVesselStateData}
                    futurePath={futurePathData}
                    journeyMarkerState={journeyMarkerState}
                    vesselStaysData={vesselStaysData}
                    map={map}
                    setMap={setMap}
                    vesselPositions={vesselPositions}
                  />
                ) : ""}
              </>
            </ErrorBoundary>
            {(chosenSegment && chosenVesselStateData) ? (
              <PortPredictorTimeline
                chosenVesselStateData={chosenVesselStateData}
                showSpeedGraphModal={() => {
                  setIsSpeedGraphModalShown(true)
                }}
                chosenVesselSpeedData={chosenVesselSpeedData}
                journeyMarkerState={journeyMarkerState}
                dispatchEditJourney={dispatchEditJourney}
                dispatchExtendJourneyMarkers={dispatchExtendJourneyMarkers}
                dispatchAddJourneyStartMarkers={dispatchAddJourneyStartMarkers}
                segment={chosenSegment.value ? chosenSegment.value : defaultSegment!}
                journeyStartMarkersArr={journeyStartMarkersArr}
                map={map}
                markers={getMarkersForMapBound({
                  journeyMarkerState,
                  vesselState: chosenVesselStateData,
                })}
              />
            ) : ""}
          </ErrorBoundary>
        </Card.Body>
      </Card>
    </>
  )
}

function getMarkersForMapBound({
  vesselState,
  journeyMarkerState,
}: {
  journeyMarkerState: PortPredictorJourneyState
  vesselState: PortPredictorVesselStateDataWithMajorCargo
}) {
  const initialPosition = {
    lat: vesselState.last_pos_lat,
    lon: vesselState.last_pos_lon,
  }
  const result = [initialPosition]

  const arrivalMarkers = journeyMarkerState.filter(
    (marker) => marker.markerType === MarkerType.Arrival
  )

  for (let arrivalMarker of arrivalMarkers) {
    if (arrivalMarker.port) {
      const portLocation = {
        lat: arrivalMarker.port.lat,
        lon: arrivalMarker.port.lon,
        markerId: arrivalMarker.id,
      }
      result.push(portLocation)
    }
  }

  return fixAntimeridian(result)
}
