import { useEffect, useState } from "react"
import Select, { SingleValue } from "react-select"
import { VesselSegment } from "user/userService"
import { SaveToLocalStorage } from "../../user/userSlice"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

export type ImoNumber = string

export interface VesselListSingle {
  label: string
  value: ImoNumber
}

export interface VesselSegmentInputSelection {
  label: string
  value: VesselSegment
}

export interface ChosenVesselForSelect {
  imo: number
  segment?: string
}

export interface SelectOption {
  label: string
  value: string
}
interface VesselSearchProps {
  vesselsData: VesselListSingle[] | undefined
  defaultSegment: VesselSegmentInputSelection
  getVesselsLoading: boolean
  getChosenVesselLoading: boolean
  chosenSegment: VesselSegmentInputSelection
  getChosenVessel: ({ name, imo }: { imo: number; name: string }) => void
  setChosenSegment: (segment: VesselSegmentInputSelection) => void
  getVessels: ({ segment }: { segment: string }) => void
  vesselSegments: VesselSegmentInputSelection[]
  defaultVessel: SelectOption
}

export function VesselSearch({
  vesselsData,
  getVesselsLoading,
  chosenSegment,
  getChosenVessel,
  defaultSegment,
  setChosenSegment,
  getVessels,
  vesselSegments,
  defaultVessel
}: VesselSearchProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  const [chosenVessel, setChosenVessel] = useState<SelectOption | undefined>(defaultVessel)
  
  useEffect(() => {
    setChosenVessel(defaultVessel)
  }, [defaultVessel])

  return (
    <>
      <div className="VesselSearch_Label">
        <h5 className="mb-0">Search Vessel</h5>
      </div>
      <Select
        isDisabled={getVesselsLoading || !getTabStateKeyValue('isEditMode')}
        className="VesselSearch_Name"
        options={vesselsData}
        value={chosenVessel?.value ? chosenVessel : null}
        placeholder={
          getVesselsLoading
            ? `Loading ${chosenSegment.label} vessels...`
            : "Search by Vessel Name / IMO Number"
        }
        onChange={(vessel: SingleValue<SelectOption>) => {
          if (!vessel) {
            return
          }
          setChosenVessel(vessel)
          getChosenVessel({ name: vessel.label, imo: Number(vessel.value) })
        }}
      />

      <div className="VesselSearch_Segment">
        <Select
          isDisabled={!getTabStateKeyValue('isEditMode')}
          defaultValue={defaultSegment}
          options={vesselSegments}
          value={defaultSegment ? defaultSegment : null}
          onChange={(segment: SingleValue<VesselSegmentInputSelection>) => {
            if (!segment) {
              return
            }
            setChosenVessel(undefined)
            setChosenSegment(segment)
            SaveToLocalStorage("defaultSegment", segment.value)
            getVessels({ segment: segment.value })
          }}
        />
      </div>
    </>
  )
}
