import React from "react"
import { PowerBiWrapper } from "modules/PowerBi/PowerBiWrapper"
import { PowerbiPreviewProps } from "../../shared/types"
import { Button, Modal } from "react-bootstrap"

const PowerbiPreview: React.FC<PowerbiPreviewProps> = ({
  title,
  breadcrumbItem,
  sectionTitle,
  reportId,
  groupId,
  permission,
  pageName,
  show,
  handleClose,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Preview PBI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PowerBiWrapper
            title={title}
            breadcrumbItem={breadcrumbItem}
            sectionTitle={sectionTitle}
            reportId={reportId}
            groupId={groupId}
            permission={permission}
            pageName={pageName}
            isPreview={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-dark border border-dark" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PowerbiPreview
