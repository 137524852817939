import { useQuery, useQueryClient } from "@tanstack/react-query"
import { PortPredictorVesselSpeed, usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchVesselJourneySpeed(imo: number | undefined, savedChosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined) {
  const { getChosenVesselSpeed } = usePortPredictorAPI()
  const queryClient = useQueryClient()

  const shouldNotFetch = !!savedChosenVesselSpeedData;
  queryClient.invalidateQueries({queryKey: ["portPredictor/getChosenVesselSpeed", imo, shouldNotFetch]})

  const {
    isLoading: isChosenVesselSpeedLoading,
    isError: isChosenVesselSpeedError,
    isSuccess: isChosenVesselSpeedSuccess,
    data: chosenVesselSpeedData,
  } = useQuery({
    queryKey: ["portPredictor/getChosenVesselSpeed", imo, shouldNotFetch],
    queryFn: ({ queryKey }) => {
      if (shouldNotFetch) return savedChosenVesselSpeedData
      const result = getChosenVesselSpeed(queryKey[1] as number)
      return result
    },
    enabled: imo !== undefined,
    staleTime: shouldNotFetch ? 600000 : 0, // 10 minutes
  })


  return {
    isChosenVesselSpeedLoading,
    isChosenVesselSpeedError,
    isChosenVesselSpeedSuccess,
    chosenVesselSpeedData,
  }
}
