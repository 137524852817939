import BigNumber from "bignumber.js"
import {
  PortPredictorVesselSpeed,
  VesselPositionState,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  VesselStateLadenOrBallast,
} from "modules/PortPredictor/portPredictorReducer"
import moment from "moment"
import { Accordion } from "react-bootstrap"
import { getVesselSpeedDataWithAverages } from "./helpers"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

export function PortPredictorDisplayDeparture({
  currMarker,
  nextMarker,
  changeOpenSeaToPort,
  chosenVesselSpeedData,
}: {
  currMarker: PortPredictorMarkerDeparture
  nextMarker: PortPredictorMarkerArrival | undefined
  changeOpenSeaToPort: () => void
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
}) {
  const { getTabStateKeyValue } = useItineraryContext()
  const isChosenVesselSpeedAverageSpeed = getIsChosenVesselSpeedAverageSpeed({
    chosenVesselSpeedData,
    currMarker,
  })

  return (
    <div className="PortPredictor_Display mb-5">
      <div>
        {currMarker.id === 0 && "Latest AIS Signal: "}
        {currMarker.id !== 0 && "Depart: "}
        {currMarker.id === 0 && currMarker.date && (
          <span className="fw-bold">{formatDateForAIS(currMarker.date)}</span>
        )}
        {currMarker.id !== 0 && currMarker.date && (
          <span className="fw-bold">
            {moment.utc(currMarker.date).local().format("D MMM YYYY")}
          </span>
        )}{" "}
        {currMarker.id !== 0 && "(ETD)"}
      </div>
      {currMarker.port && <div>{currMarker.port?.port}</div>}
      {currMarker.id === 0 &&
      currMarker.vesselPosition === VesselPositionState.OpenSea ? (
        <Accordion>
          <Accordion.Item
            eventKey={`${currMarker.id}_${currMarker.date}_${currMarker.vesselPosition}`}
          >
            <Accordion.Header>{VesselPositionState.OpenSea}</Accordion.Header>
            <Accordion.Body>
              <div className="flex-fill" style={{ maxWidth: "250px" }}>
                <div
                  onClick={() => {
                    if(getTabStateKeyValue('isEditMode')) {
                      changeOpenSeaToPort()
                    }
                  }}
                >
                  Change to Port
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div>{currMarker.vesselPosition}</div>
      )}
      <div className="mb-1">
        {currMarker.lastNavStaDesc === "Under way using engine"
          ? "Under way"
          : currMarker.lastNavStaDesc}
      </div>
      <div>
        <span>
          Avg. {currMarker.vesselState} Speed:{" "}
          {currMarker.vesselSpeedSelected
            ? BigNumber(currMarker.vesselSpeedSelected)
                .decimalPlaces(2, BigNumber.ROUND_HALF_DOWN)
                .toString()
            : ""}{" "}
          Kts.
        </span>
        {!isChosenVesselSpeedAverageSpeed && (
          <div
            style={{ borderRadius: "4px" }}
            className="bg-yellow d-inline ms-2 ps-1 pe-1"
          >
            <i className="ti-alert me-1" />
            <span>Manual input by user</span>
          </div>
        )}
      </div>
      {currMarker.daysToArrival && currMarker.distanceToArrival && nextMarker && (
        <div>
          <span>
            {currMarker.daysToArrival} Days to {nextMarker.port?.port}
          </span>
          <span className="ms-1">
            (
            {BigNumber(currMarker.distanceToArrival)
              .decimalPlaces(1, BigNumber.ROUND_CEIL)
              .toNumber()}{" "}
            N.M)
          </span>
        </div>
      )}
    </div>
  )
}

export function getIsChosenVesselSpeedAverageSpeed({
  chosenVesselSpeedData,
  currMarker,
}: {
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  currMarker: PortPredictorMarkerDeparture
}) {
  const speedDataWithAvg = getVesselSpeedDataWithAverages(chosenVesselSpeedData)
  const vesselState = currMarker.vesselState

  if (!speedDataWithAvg) {
    return false
  }

  if (vesselState === VesselStateLadenOrBallast.Laden) {
    if (
      speedDataWithAvg.laden.sixMonths.avg &&
      currMarker.vesselSpeedSelected === speedDataWithAvg.laden.sixMonths.avg
    ) {
      return true
    }
    if (
      speedDataWithAvg.laden.threeMonths.avg &&
      currMarker.vesselSpeedSelected === speedDataWithAvg.laden.threeMonths.avg
    ) {
      return true
    }
  }

  if (vesselState === VesselStateLadenOrBallast.Ballast) {
    if (
      speedDataWithAvg.ballast.sixMonths.avg &&
      currMarker.vesselSpeedSelected === speedDataWithAvg.ballast.sixMonths.avg
    ) {
      return true
    }
    if (
      speedDataWithAvg.ballast.threeMonths.avg &&
      currMarker.vesselSpeedSelected ===
        speedDataWithAvg.ballast.threeMonths.avg
    ) {
      return true
    }
  }

  return false
}

function formatDateForAIS(date: string) {
  const formattedDate = moment
    .utc(date)
    .local()
    .format("D MMM YYYY HH:mm [GMT]Z")

  // Now, remove the extra zeros from the timezone offset
  const cleanFormattedDate = formattedDate.replace(/:00$/, "")
  return cleanFormattedDate
}
