import React, { useEffect, useState } from "react"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import MdEditor, { Plugins } from "react-markdown-editor-lite"
import "react-markdown-editor-lite/lib/index.css"
import MarkdownIt from "markdown-it"
import { useCreateMaintenance } from "modules/Admin/MaintenanceNotification/hooks/useCreateMaintenance"
import { useEditMaintenance } from "modules/Admin/MaintenanceNotification/hooks/useEditMaintenance"
import { toast } from "react-toastify"
import { now } from "lodash"
import DatePicker from "react-datepicker";

MdEditor.unuse(Plugins.TabInsert)
MdEditor.unuse(Plugins.FullScreen)
MdEditor.unuse(Plugins.ModeToggle)
MdEditor.unuse(Plugins.Image)
MdEditor.unuse(Plugins.Table)

const mdParser = new MarkdownIt()

interface MaintenanceFormProps {
  startDate: string
  endDate: string
  message: string
  setStartDate: (date: string) => void
  setEndDate: (date: string) => void
  setMessage: (message: string) => void
  handleEditorChange: ({ html, text }: { html: string; text: string }) => void
  handleClose: () => void
  handlePreviewShow: () => void
  refreshMaintenanceList: () => void
  mode: "create" | "edit"
  id?: string
}

const MaintenanceForm: React.FC<MaintenanceFormProps> = ({
  startDate,
  endDate,
  message,
  setStartDate,
  setEndDate,
  setMessage,
  handleEditorChange,
  handleClose,
  handlePreviewShow,
  refreshMaintenanceList,
  mode,
  id,
}) => {
  const { createMaintenance, isLoading: isCreating } = useCreateMaintenance({
    handleCreateMaintenance: (data) => {
      handleClose()
      refreshMaintenanceList()
    },
  })

  const { editMaintenance, isLoading: isEditing } = useEditMaintenance({
    handleEditMaintenance: (data) => {
      handleClose()
      refreshMaintenanceList()
    },
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (message.trim() === ""){
      toast.error("Maintenance message must not be empty", {
        position: "bottom-right",
        autoClose: 8000,
      })
      return
    }

    if (new Date(endDate) < new Date(startDate)) {
      toast.error("End date must not be before start date", {
        position: "bottom-right",
        autoClose: 8000,
      })
      return
    }

    if (mode != "edit") {
      if (
        new Date(endDate) < new Date(now()) ||
        new Date(startDate) < new Date(now())
      ) {
        toast.error(
          "Start date or end date cannot be before current date time",
          {
            position: "bottom-right",
            autoClose: 8000,
          }
        )
        return
      }
    }

    const maintenanceStart = new Date(`${startDate}:00.000+08:00`).toISOString()
    const maintenanceEnd = new Date(`${endDate}:00.000+08:00`).toISOString()

    const params = {
      maintenanceStart: maintenanceStart,
      maintenanceEnd: maintenanceEnd,
      maintenanceMesasge: message,
    }

    if (mode === "create") {
      await createMaintenance(params)
    } else if (mode === "edit" && id) {
      await editMaintenance({ id, ...params })
    }
    //set params back to original
    const datenow = new Date()
    const utc8Now = new Date(datenow.getTime() + 8 * 60 * 60 * 1000)
    const defaultDate = utc8Now.toISOString().slice(0, 16)
    setStartDate(defaultDate)
    setEndDate(defaultDate)
    setMessage("")
  }


  useEffect(() => {
    const formatDateTimeLocal = (dateString: string) => {
      const date = new Date(dateString)
      const offset = date.getTimezoneOffset()
      const localDate = new Date(date.getTime() - offset * 60 * 1000)
      return localDate.toISOString().slice(0, 16)
    }

    setStartDate(formatDateTimeLocal(startDate))
    setEndDate(formatDateTimeLocal(endDate))
  }, [startDate, endDate, setStartDate, setEndDate])
 
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formStartDate" className="mt-3">
        <Form.Label className="w-100">Maintenance Start</Form.Label>
       {/*  <Form.Control
          className="w-100"
          type="datetime-local"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        
        /> */}
        <DatePicker
          selected={new Date(startDate)}
          onChange={(e)=> setStartDate(e!.toString())}
          showTimeSelect
          dateFormat="Pp"
          className="form-control px-2 w-100"
          />
      </Form.Group>

      <Form.Group controlId="formEndDate" className="mt-3">
        <Form.Label className="w-100">Maintenance End</Form.Label>
       {/*  <Form.Control
          className="w-100"
          type="datetime-local"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        /> */}
         <DatePicker
          selected={new Date(endDate)}
          onChange={(e)=> setEndDate(e!.toString())}
          showTimeSelect
          dateFormat="Pp"
          className="form-control px-2 w-100"
          />
      </Form.Group>

      <MdEditor
        className="mt-3"
        value={message}
        style={{ height: "400px" }}
        placeholder="Enter Maintenance Message Here"
        renderHTML={(text) => mdParser.render(text)}
        onChange={(msg) => setMessage(msg.text)}
        config={{
          view: {
            menu: true,
            md: true,
            html: false,
          },
          canView: {
            menu: true,
            md: false,
            html: false,
          },
        }}
      />
      <div
        className="position-absolute w-100"
        style={{
          bottom: 0,
          left: 0,
          padding: "1rem",
          backgroundColor: "white",
        }}
      >
        <Container fluid>
          <Row className="justify-content-start">
            <Col xs="auto" className="d-flex flex-row">
              <Button
                variant="secondary"
                className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0 me-2"
                onClick={handlePreviewShow}
              >
                <i className="ti-eye"></i>Preview
              </Button>
              <Button
                variant="primary"
                className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
                type="submit"
                disabled={isCreating || isEditing}
              >
                {isCreating || isEditing ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {mode === "create" ? "Creating..." : "Saving..."}
                  </>
                ) : (
                  <>
                    <i className="ti-check"></i>
                    {mode === "create" ? "Save" : "Update"}
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  )
}

export default MaintenanceForm
