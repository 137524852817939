import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { toast } from "react-toastify"
import { SidebarItem } from "../shared/types"

export function useGetSidebarItems() {
  const request = useAxiosRequest()
  const queryClient = useQueryClient()

  const fetchSidebarItems = async (): Promise<SidebarItem[]> => {
    const url = "api/v1/sidebar?bypassPermission=true"

    try {
      const response = await request<any, SidebarItem[]>({
        url,
        method: "GET",
      })

      return response?.data || []
    } catch (err: unknown) {
      console.error("Error fetching sidebar Items", err)
      let message = "Error fetching sidebar Items"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = message + "."
      }
      toast.error(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
      throw new Error(message)
    }
  }

  const {
    isLoading,
    error,
    data: sidebarItems,
  } = useQuery<SidebarItem[]>({
    queryKey: ["sidebarItems"],
    queryFn: fetchSidebarItems,
  })

  return {
    isLoading,
    sidebarItems,
    error,
  }
}
