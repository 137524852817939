import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchPortsTerminals() {
  const { getPortsTerminals } = usePortPredictorAPI()

  const {
    isLoading: isPortsTerminalsLoading,
    isError: isPortsTerminalsError,
    isSuccess: isPortsTerminalsSuccess,
    data: portsTerminalsData,
  } = useQuery({
    queryKey: ["portPredictor/getPortsTerminals"],
    queryFn: ({ queryKey }) => getPortsTerminals(),
    staleTime: 600000, // 10 minutes
  })

  return {
    portsTerminalsData,
    isPortsTerminalsLoading,
    isPortsTerminalsError,
    isPortsTerminalsSuccess,
  }
}
