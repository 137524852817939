import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useState, useEffect, useCallback, useRef } from "react"
import { toast } from "react-toastify"

interface MaintenanceItem {
  id: string
  maintenanceStart: string
  maintenanceEnd: string
  maintenanceMesasge: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  isDeleted: boolean
}

interface MaintenanceList {
  Previous: MaintenanceItem[]
  Current: MaintenanceItem[]
  UpComing: MaintenanceItem[]
}

export function useGetMaintenance() {
  const request = useAxiosRequest()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [maintenanceList, setMaintenanceList] = useState<MaintenanceList>({
    Previous: [],
    Current: [],
    UpComing: [],
  })
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const fetchMaintenance = useCallback(
    async (url: string) => {
      try {
        setIsLoading(true)
        const response = await request<any, MaintenanceList>({
          url,
          method: "GET",
        })
        if (isMounted.current) {
          if (response && response.data) {
            setMaintenanceList(response.data)
          }
        }
      } catch (err: unknown) {
        console.error("Error fetching maintenance data:", err) // Debugging log
      } finally {
        if (isMounted.current) {
          setIsLoading(false)
        }
      }
    },
    [request]
  )

  const getMaintenance = useCallback(() => {
    fetchMaintenance("api/v1/maintenance/")
  }, [fetchMaintenance])

  const getSingleMaintenance = useCallback(() => {
    fetchMaintenance("api/v1/maintenance/single")
  }, [fetchMaintenance])

  return {
    getMaintenance,
    getSingleMaintenance,
    maintenanceList,
    isLoading,
  }
}
