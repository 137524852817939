import Breadcrumbs from "components/Common/Breadcrumbs"
import PowerBi from "components/Common/PowerBi"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { usePowerBiEmbedToken } from "hooks/usePowerBiEmbedToken"
import { models } from "powerbi-client"

import React from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"

interface WrapperProps {
  title: string
  breadcrumbItem: string
  sectionTitle: string
  reportId: string
  groupId: string
  permission: string
  pageName: string
  isPreview: boolean
}

function toCaps(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function formatLabel(label: string) {
  if (label.split("-").length > 1) {
    const formatted = label
      .split("-")
      .map((word) => {
        return toCaps(word)
      })
      .join(" ")
    return formatted
  }
  return toCaps(label)
}

export function PowerBiWrapper(props: WrapperProps) {
  const {
    reportId,
    groupId,
    pageName,
    permission,
    sectionTitle,
    title,
    breadcrumbItem,
    isPreview,
  } = props

  const { token: embedToken, tokenLoading } = usePowerBiEmbedToken({
    groupId,
    reportId,
    permission,
    pageName,
    isPreview,
  })

  const { pathname: currentPath } = useLocation()

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container fluid>
          <div className="main-content_inner">
            <Breadcrumbs
              title={formatLabel(currentPath.split("/")[1])}
              breadcrumbItem={breadcrumbItem}
            />
            {!embedToken && tokenLoading ? (
              <ContentLoading />
            ) : (
              <PowerBi
                hasPerms={true}
                embedToken={embedToken}
                embedConfig={{
                  type: "report",
                  id: reportId,
                  embedUrl:
                    "https://app.powerbi.com/reportEmbed?reportId=" +
                    reportId +
                    "&groupId=" +
                    groupId,
                  accessToken: embedToken?.token,
                  tokenType: models.TokenType.Embed,
                  pageName: pageName,
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
