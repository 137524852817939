import { useQuery, useQueryClient } from "@tanstack/react-query"
import { PortPredictorVesselStateDataWithMajorCargo, usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchVesselJourneyStart(imo: number | undefined, savedChosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined) {
  const { getChosenVesselState } = usePortPredictorAPI()
  const queryClient = useQueryClient()

  const shouldNotFetch = !!savedChosenVesselStateData;
  queryClient.invalidateQueries({queryKey: ["portPredictor/getChosenVesselState", imo, shouldNotFetch]})

  const {
    isLoading: isChosenVesselStateLoading,
    isError: isChosenVesselStateError,
    isSuccess: isChosenVesselStateSuccess,
    data: chosenVesselStateData,
  } = useQuery({
    queryKey: ["portPredictor/getChosenVesselState", imo, shouldNotFetch],
    queryFn: ({ queryKey }) => {
      if (shouldNotFetch) return savedChosenVesselStateData
      const result = getChosenVesselState(queryKey[1] as number)
      return result
    },
    enabled: imo !== undefined,
    staleTime: 0, // 10 minutes
  })
  return {
    chosenVesselStateData,
    isChosenVesselStateLoading,
    isChosenVesselStateError,
    isChosenVesselStateSuccess,
  }
}
