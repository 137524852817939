import { SelectProps } from "./types"
export const PERMISSION_CREATE_MENU_PBI = "menu-admin-pbi-only"
export const PERMISSION_UPDATE_MENU_PBI = "menu-admin-pbi-only"
export const PERMISSION_DELETE_MENU_PBI = "menu-admin-pbi-only"
export const PARENT_MENU_PLACEHOLDER = "Enter path here..."
export const selectBoolOptions: SelectProps[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]

export const selectBoolOptionsPbi: SelectProps[] = [
    {value: true, label: "Yes"}
]

export const CREATE_PERMISSION = "Create Permission"

