import { useCallback, useEffect, useReducer, useState } from "react"
import { Nav } from "react-bootstrap"

import axios from "axios"

import {
  Itinerary,
  Markers,
  useItineraries,
} from "modules/PortPredictor/hooks/useItineraryAPI"
import { PortPredictorJourneyMarker } from "modules/PortPredictor/portPredictorReducer"
import { PortPredictor } from "../PortPredictorMain"
import {
  ModalDeleteConfirmation,
  ModalWithInput
} from "./ItineraryModals"
import {
  DUPLICATE_SELECTED_ITINERARY,
  InitialItineraryState,
  ItineraryReducer,
  NEW_ITINERARY,
  OPEN_SELECTED_SAVED_ITINERARY,
  REMOVE_OPENED_ITINERARY,
  RENAME_SELECTED_ITINERARY,
  SAVE_SELECTED_ITINERARY,
  SET_ITINERARIES,
  SET_SELECTED_ITINERARY_ID_AND_OPEN,
  UPDATE_ITINERARY,
} from "./ItineraryReducer"
import {
  ConstructAddTab,
  ConstructItineraryTab,
} from "./ItineraryTab"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

export function ItineraryMain() {
  const [currentItineraryName, setCurrentItineraryName] = useState("")
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showDuplicate, setShowDuplicate] = useState(false)
  const [showRename, setShowRename] = useState(false)
  const [showSaveNew, setShowSaveNew] = useState(false)
  const [itineraryWithMarker, setItineraryWithMarker] = useState<Itinerary>()

  const { itineraryState, setItineraryState, getItineraryMarker, itineraryDispatch, getTabStateKeyValue, setTabStateKeyValue } = useItineraryContext()

  const {
    itineraries,
    createItinerary,
    updateItinerary,
    deleteItinerary,
    getItineraryWithMarker,
    itineraryWithMarker: fetchedItineraryWithMarker,
    isLoading,
    isItineraryWithMarkerLoading,
  } = useItineraries("")

  // Set context itineraryState
  useEffect(() => {
    if (itineraryState) {
      setItineraryState(itineraryState)
    }
  }, [itineraryState])

  useEffect(() => {
    if (Array.isArray(itineraries)) {
      itineraryDispatch({
        type: SET_ITINERARIES,
        payload: itineraries,
      })
    }
  }, [itineraries])

  useEffect(() => {
    if (itineraries && itineraryState.itineraries?.length && itineraryState.itineraries[0].id === -1) {
      let addNew = itineraries.length === 0
      if (itineraries.length > 0 &&
        itineraryState.itineraries.length === 1 && itineraryState.itineraries[0].id === -1) {
        addNew = true
      }
      if (itineraryState.itineraries[0].isOpened) {
        itineraryDispatch({
          type: REMOVE_OPENED_ITINERARY,
          payload: { id: -1, addNew }
        })
      }
    }
    if (itineraries && !itineraryState.itineraries) {
      itineraryDispatch({ type: NEW_ITINERARY })
    }
  }, [itineraryState, itineraries])

  // Add new once loaded
  useEffect(() => {
    const currentItinerary = GetSelectedItinerary(itineraryState.selectedId)
    if (currentItinerary && !currentItinerary.isOpened) {
      itineraryDispatch({
        type: OPEN_SELECTED_SAVED_ITINERARY,
        payload: currentItinerary,
      })
    }
  }, [itineraryState.itineraries])

  useEffect(() => {
    if (itineraryWithMarker) {
      itineraryDispatch({
        type: UPDATE_ITINERARY,
        payload: {
          id: itineraryState.selectedId,
          markers: itineraryWithMarker.markers,
        },
      })
    }
  }, [itineraryWithMarker])
  
  useEffect(() => {
    if (fetchedItineraryWithMarker) {
      setItineraryWithMarker(fetchedItineraryWithMarker)
    }
  }, [fetchedItineraryWithMarker])

  useEffect(() => {
    const exec = async () => {
      if (itineraryState && itineraryState.itineraries) {
        
        const selectedItinerary = GetSelectedItinerary(parseInt("0" + itineraryState.selectedId))
        if (
          selectedItinerary &&
          (!selectedItinerary.markers ||
            Object.keys(selectedItinerary.markers).length === 0)
        ) {
          
          if (!selectedItinerary.uuid) {
            setItineraryWithMarker(selectedItinerary)
          } else {
            await getItineraryWithMarker(selectedItinerary?.uuid)
          }
        } else {
          setItineraryWithMarker(selectedItinerary)
        }
        setTabStateKeyValue('isEditMode', !!selectedItinerary?.isEditMode)
      }
      
      setTabStateKeyValue('isModified', true)
    }
    exec()
  }, [itineraryState.selectedId, fetchedItineraryWithMarker])

  const editHandler = useCallback(async (isEditMode: boolean) => {
    setTabStateKeyValue('isEditMode', isEditMode)

  }, [itineraryState.selectedId])

  const cancelHandler = async () => {
    const currentSelected = GetSelectedItinerary(itineraryState.selectedId)
    if (currentSelected && currentSelected?.uuid) {
      await getItineraryWithMarker(currentSelected?.uuid)
      setItineraryWithMarker(fetchedItineraryWithMarker)
      itineraryDispatch({
        type: UPDATE_ITINERARY,
        payload: {
          id: itineraryState.selectedId,
          markers: fetchedItineraryWithMarker.markers,
        },
      })

    }
    setTabStateKeyValue('isEditMode', false)
    setTabStateKeyValue('isModified', false)
  }

  const newHandler = useCallback(async () => {
    itineraryDispatch({ type: NEW_ITINERARY })
    setTabStateKeyValue('isEditMode', true)
  }, [itineraryDispatch]) 

  const renameHandler = useCallback(
    async (newName: string): Promise<void> => {
      try {
        const updatedItinerary = {
          ...GetSelectedItinerary(itineraryState.selectedId),
          name: newName,
        }
        const result = await updateItinerary(updatedItinerary.id!, updatedItinerary)
        itineraryDispatch({
          type: SAVE_SELECTED_ITINERARY,
          payload: {
            prev: itineraryState.selectedId,
            updated: parseInt("0" + result.id),
            updateKeys: undefined
          },
        })
        itineraryDispatch({
          type: RENAME_SELECTED_ITINERARY,
          payload: { id: itineraryState.selectedId, name: newName },
        })
        await getItineraryWithMarker(result.uuid)
        setTabStateKeyValue('isModified', !!updatedItinerary.isModified)
        setShowRename(false)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setShowRename(false)
          setCurrentItineraryName(newName)
          setShowRename(true)
        }
        throw err
      }
    },
    [itineraryState, updateItinerary]
  )

  const duplicateHandler = useCallback(
    async (duplicateName: string): Promise<void> => {
      try {
        const duplicatedItinerary = {
          ...GetSelectedItinerary(itineraryState.selectedId),
          name: duplicateName,
          uuid: undefined,
        }
        const result = await createItinerary(duplicatedItinerary)
        itineraryDispatch({
          type: DUPLICATE_SELECTED_ITINERARY,
          payload: {
            prev: itineraryState.selectedId,
            updated: parseInt("0" + result.id),
          },
        })
        await getItineraryWithMarker(result?.uuid)
        setShowDuplicate(false)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setShowDuplicate(false)
          setCurrentItineraryName(duplicateName)
          setShowDuplicate(true)
        }
        throw err
      }
    },
    [itineraryState, createItinerary]
  )

  const deleteHandler = useCallback(async (): Promise<void> => {
    const selectedItinerary = GetSelectedItinerary(itineraryState.selectedId)
    await deleteItinerary(selectedItinerary?.uuid || "")
    itineraryDispatch({
      type: REMOVE_OPENED_ITINERARY,
      payload: { id: itineraryState.selectedId, addNew: true }
    })
    setShowDeleteConfirmation(false)
  }, [itineraryState, deleteItinerary])

  const menuDeleteHandler = useCallback(async (id: number, name: string): Promise<void> => {
    itineraryDispatch({
      type: SET_SELECTED_ITINERARY_ID_AND_OPEN,
      payload: id,
    })
    setCurrentItineraryName(name)
    setShowDeleteConfirmation(true)
  }, [itineraryState, deleteItinerary])

  const saveHandler = useCallback(
    async (updatedName: string | undefined): Promise<void> => {
      if (itineraryState.itineraries) {
        const currentSelected = GetSelectedItinerary(itineraryState.selectedId)
        if (currentSelected) {
          try {
            if (updatedName) {
              currentSelected.name = updatedName
            }
            if (currentSelected.isNew && !updatedName) {
              setCurrentItineraryName(currentSelected.name)
              setShowSaveNew(true)
              return
            }
            
            setTabStateKeyValue('isEditMode', false)
            setTabStateKeyValue('isModified', false)
            const result = await createItinerary(currentSelected)
            result.isEditMode = false
            result.isModified = false
            itineraryDispatch({
              type: SAVE_SELECTED_ITINERARY,
              payload: {
                prev: itineraryState.selectedId,
                updated: parseInt("0" + result.id),
                updateKeys: { ...result }
              },
            })
            await getItineraryWithMarker(result.uuid)
          } catch (err) {
            if (axios.isAxiosError(err)) {
              setShowSaveNew(false)
              setCurrentItineraryName(currentSelected.name)
              setShowSaveNew(true)
            }
            throw err
          }
        }
      }
    },
    [itineraryState, createItinerary]
  )

  const closeTabHandler = useCallback(async (id: number): Promise<void> => {
    itineraryDispatch({
      type: REMOVE_OPENED_ITINERARY,
      payload: { id, addNew: true }
    })
  }, [itineraryState])

  const addItineraryHandler = useCallback(
    async (itinerary: Itinerary) => {
      if (itinerary.uuid) {
        itineraryDispatch({
          type: OPEN_SELECTED_SAVED_ITINERARY,
          payload: itinerary,
        })
        await getItineraryWithMarker(itinerary.uuid)
        setTabStateKeyValue('isModified', true)
        setTabStateKeyValue('isEditMode', true)
      }

    },
    [itineraryDispatch]
  )

  const updatePortPortalItineraryMarkers = (
    itineraryMarkers: Markers,
    chosenId: Number
  ) => {
    itineraryDispatch({
      type: UPDATE_ITINERARY,
      payload: {
        id: itineraryState.selectedId,
        markers: itineraryMarkers
      },
    })
  }

  const modalDecorator = useCallback(
    (wrappedFunc: void) => {
      const itineraryName =
        (itineraryState.itineraries
          ? itineraryState.itineraries.find(
            (item) => item.id === itineraryState.selectedId
          )?.name
          : "") || ""
      setCurrentItineraryName(itineraryName)
      return wrappedFunc
    },
    [itineraryState]
  )

  const GetSelectedItinerary = useCallback((id: number): Itinerary | undefined => {
    return itineraryState.itineraries?.find(
      (item) => item.id === id
    );
  }, [itineraryState]);

  const GetItineraryValue = useCallback(() => {
    if (fetchedItineraryWithMarker && !getTabStateKeyValue('isEditMode') && !getTabStateKeyValue('isModified') && getTabStateKeyValue('isOpened')) {
      return fetchedItineraryWithMarker.markers // getItineraryMarker(itineraryState.selectedId)
    }
    return (itineraryWithMarker && itineraryWithMarker.markers) ? itineraryWithMarker.markers : { data: [] as PortPredictorJourneyMarker[], meta: {} } as Markers
    }, [itineraryWithMarker, fetchedItineraryWithMarker, itineraryState.selectedId])

  return (
    <>
      <Nav
        variant="tabs"
        defaultActiveKey={"0"}
        onSelect={(selectedId: string | null) => {
          // Do not dispatch if selectedKey is not number - from command menus such "new", "rename" and etc.
          if (!isNaN(Number(selectedId))) {
            itineraryDispatch({
              type: SET_SELECTED_ITINERARY_ID_AND_OPEN,
              payload: parseInt("0" + selectedId),
            })
          }
        }}
        key="mainNav"
      >
        {ConstructItineraryTab(
          itineraryState.itineraries,
          itineraryState.selectedId,
          () => editHandler(true),
          () => cancelHandler(),
          saveHandler,
          () => modalDecorator(setShowRename(true)),
          () => modalDecorator(setShowDuplicate(true)),
          () => modalDecorator(setShowDeleteConfirmation(true)),
          closeTabHandler
        )}
        {ConstructAddTab(
          itineraryState.itineraries,
          newHandler,
          addItineraryHandler,
          menuDeleteHandler
        )}

      </Nav>
      <PortPredictor
        updatePortPortalItineraryMarkers={updatePortPortalItineraryMarkers}
        initialSavedMarkers={GetItineraryValue()}
        selectedId={itineraryState.selectedId}
        keyState={getTabStateKeyValue('isEditMode') + "," + getTabStateKeyValue('isModified') + "," + getTabStateKeyValue('isOpened')+ "," + getTabStateKeyValue('isNew')}
      />
      {
        // Rename modal dialog box
        ModalWithInput(
          showRename,
          currentItineraryName,
          "Update voyage name",
          "New voyage name",
          renameHandler,
          () => setShowRename(false)
        )
      }
      {
        // Duplicate modal dialog box
        ModalWithInput(
          showDuplicate,
          currentItineraryName + " copy",
          "Duplicate voyage",
          "Voyage name",
          duplicateHandler,
          () => setShowDuplicate(false)
        )
      }
      {
        // SaveNew modal dialog box
        ModalWithInput(
          showSaveNew,
          currentItineraryName,
          "Enter a new voyage name",
          "Name",
          saveHandler,
          () => setShowSaveNew(false)
        )
      }
      {
        // Delete modal dialog box
        ModalDeleteConfirmation(
          showDeleteConfirmation,
          currentItineraryName,
          deleteHandler,
          () => setShowDeleteConfirmation(false)
        )
      }
    </>
  )
}
